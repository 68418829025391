import { FieldType } from "../constants/enum"

const NutritionInformation = (output) => ({
  name: "Quantity (per 100 g/ml)",
  description: "",
  fields: [
    {
      title: "Energy (kcal) per 100g/ml",
      type: FieldType.NUMBER,
      reference: "",
      validator: [
        `currentValue == undefined || (currentValue === "" || !(currentValue?.includes('.')) && Number.isInteger(parseFloat(currentValue ?? "0")))`,
        `currentValue == undefined || currentValue == "" || parseFloat(currentValue ?? 0) <= 999`,
        `currentValue == undefined || currentValue?.toString() == "" || currentValue === "" || parseFloat(currentValue ?? "0") >= 0`,
      ],
      error: [
        "Energy must be a whole number.",
        "Energy should not exceed 999 kcal.",
        "Energy must be a positive value.",
      ],
      layout: 4,
      description: "",
      list: [],
      items: [],
      key: "energy",
      visible: true,
      required: true,
    },
    {
      title: "Total Carbohydrate (g) per 100g/ml",
      type: FieldType.NUMBER,
      reference: "",
      validator: [
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= 100`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") >= 0`,
        `currentValue == undefined || currentValue?.toString() == '' || parseInt(currentValue).toString() === currentValue?.toString() || parseFloat(currentValue).toFixed(1).toString() === currentValue?.toString()`,
        `currentValue == undefined || currentValue?.toString() == "" || (parseFloat(currentValue ?? "0") + parseFloat(output.protein ? output.protein : 0) + parseFloat(output.total_fat ? output.total_fat : 0)) <= 100`,
      ],
      error: [
        "Total Carbohydrate should not exceed 100g.",
        "Total Carbohydrate must be a positive value.",
        "Total Carbohydrate can have at most one decimal place.",
        "The sum of Protein, Carbohydrate, and Total Fat cannot exceed 100g.",
      ],
      layout: 4,
      description: "",
      list: [],
      items: [],
      key: "carbohydrates",
      visible: true,
      required: true,
    },
    {
      title: "Total Sugars (g) per 100g/ml",
      type: FieldType.NUMBER,
      validator: [
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= 100`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") >= 0`,
        `currentValue == undefined || currentValue?.toString() == '' || parseInt(currentValue).toString() === currentValue?.toString() || parseFloat(currentValue).toFixed(1).toString() === currentValue?.toString()`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= parseFloat(output.carbohydrates ?? "0")`,
      ],
      error: [
        "Total Sugars should not exceed 100g.",
        "Total Sugars must be a positive value.",
        "Total Sugars can have at most one decimal place.",
        "Total Sugars cannot be greater than Total Carbohydrates.",
      ],
      layout: 4,
      description: "",
      list: [],
      items: [],
      key: "free_sugars",
      visible: true,
      required: true,
    },
    {
      title: "Added Sugars (g) per 100g/ml",
      type: FieldType.NUMBER,
      reference: "",
      validator: [
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= 100`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") >= 0`,
        `currentValue == undefined || currentValue?.toString() == '' || parseInt(currentValue).toString() === currentValue?.toString() || parseFloat(currentValue).toFixed(1).toString() === currentValue?.toString()`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= parseFloat(output.free_sugars ?? "0") && parseFloat(currentValue ?? "0") <= parseFloat(output.carbohydrates ?? "0")`,
      ],
      error: [
        "Added Sugars should not exceed 100g.",
        "Added Sugars must be a positive value.",
        "Added Sugars can have at most one decimal place.",
        "Added Sugars cannot be greater than Total Sugars or Total Carbohydrates.",
      ],
      layout: 4,
      description: "",
      list: [],
      items: [],
      key: "total_free_sugars",
      visible: true,
      required: true,
    },
    {
      title: "Protein (g) per 100g/ml",
      type: FieldType.NUMBER,
      reference: "",
      validator: [
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= 100`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") >= 0`,
        `currentValue == undefined || currentValue?.toString() == '' || parseInt(currentValue).toString() === currentValue?.toString() || parseFloat(currentValue).toFixed(1).toString() === currentValue?.toString()`,
        `currentValue == undefined || currentValue?.toString() == "" || (parseFloat(currentValue ?? "0") + parseFloat(output.carbohydrates ? output.carbohydrates : 0) + parseFloat(output.total_fat ? output.total_fat : 0)) <= 100`,
      ],
      error: [
        "Protein should not exceed 100g.",
        "Protein must be a positive value.",
        "Protein can have at most one decimal place.",
        "The sum of Protein, Carbohydrate, and Total Fat cannot exceed 100g.",
      ],
      layout: 4,
      description: "",
      list: [],
      items: [],
      key: "protein",
      visible: true,
      required: true,
    },
    {
      title: "Total Fat (g) per 100g/ml",
      type: FieldType.NUMBER,
      reference: "",
      validator: [
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= 100`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") >= 0`,
        `currentValue == undefined || currentValue?.toString() == '' || parseInt(currentValue).toString() === currentValue?.toString() || parseFloat(currentValue).toFixed(1).toString() === currentValue?.toString()`,
        `currentValue == undefined || currentValue?.toString() == "" || (parseFloat(currentValue ?? "0") + parseFloat(output.protein ? output.protein : 0) + parseFloat(output.carbohydrates ? output.carbohydrates : 0)) <= 100`,
      ],
      error: [
        "Total Fat should not exceed 100g.",
        "Total Fat must be a positive value.",
        "Total Fat can have at most one decimal place.",
        "The sum of Protein, Carbohydrate, and Total Fat cannot exceed 100g.",
      ],
      layout: 4,
      description: "",
      list: [],
      items: [],
      key: "total_fat",
      visible: true,
      required: true,
    },
    {
      title: "Saturated Fat (g) per 100g/ml",
      type: FieldType.NUMBER,
      reference: "",
      validator: [
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= 100`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") >= 0`,
        `currentValue == undefined || currentValue?.toString() == '' || parseInt(currentValue).toString() === currentValue?.toString() || parseFloat(currentValue).toFixed(1).toString() === currentValue?.toString()`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= parseFloat(output.total_fat ?? "0")`,
      ],
      error: [
        "Saturated Fat should not exceed 100g.",
        "Saturated Fat must be a positive value.",
        "Saturated Fat can have at most one decimal place.",
        "Saturated Fat cannot be greater than Total Fat.",
      ],
      layout: 4,
      description: "",
      list: [],
      items: [],
      key: "total_sfa",
      visible: true,
      required:
        output &&
        output.mandatory_nutrient &&
        output.mandatory_nutrient[0] &&
        output.mandatory_nutrient[0].total_fat > 0.5,
    },
    {
      title: "Sodium (mg) per 100g/ml",
      type: FieldType.NUMBER,
      reference: "",
      validator: [
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") >= 0`,
        `currentValue == undefined || currentValue?.toString() == '' || parseInt(currentValue).toString() === currentValue?.toString() || parseFloat(currentValue).toFixed(1).toString() === currentValue?.toString()`,
      ],
      error: [
        "Sodium must be a positive value.",
        "Sodium can have at most one decimal place.",
      ],
      layout: 4,
      description: "",
      list: [],
      items: [],
      key: "sodium",
      visible: true,
      required: true,
    },
    {
      title: "Cholesterol (mg) per 100g/ml",
      type: FieldType.NUMBER,
      reference: "",
      validator: [
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= 100`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") >= 0`,
        `currentValue == undefined || currentValue?.toString() == "" || currentValue == undefined || currentValue?.toString() == '' || parseInt(currentValue).toString() === currentValue?.toString() || parseFloat(currentValue).toFixed(1).toString() === currentValue?.toString()`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= parseFloat(output.total_fat ?? "0") * 1000`,
      ],
      error: [
        "Cholesterol should not exceed 100mg.",
        "Cholesterol must be a positive value.",
        "Cholesterol can have at most one decimal place.",
        "Cholesterol cannot be greater than Total Fat.",
      ],
      layout: 4,
      description: "",
      list: [],
      items: [],
      key: "cholesterol",
      visible: true,
      required:
        output &&
        output.mandatory_nutrient &&
        output.mandatory_nutrient[0] &&
        output.mandatory_nutrient[0].total_fat > 0.5,
    },
    {
      title: "Trans Fat (g) per 100g/ml",
      type: FieldType.NUMBER,
      reference: "",
      validator: [
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= 100`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") >= 0`,
        `currentValue == undefined || currentValue?.toString() == "" || currentValue?.toString() == '' || parseInt(currentValue).toString() === currentValue?.toString() || parseFloat(currentValue).toFixed(1) == currentValue?.toString() || parseFloat(currentValue).toFixed(2) == currentValue?.toString()`,
        `currentValue == undefined || currentValue?.toString() == "" || parseFloat(currentValue ?? "0") <= parseFloat(output.total_fat ?? "0") && parseFloat(currentValue ?? "0") <= parseFloat(output.total_sfa ?? "0")`,
      ],
      error: [
        "Trans Fat should not exceed 100g.",
        "Trans Fat must be a positive value.",
        "Trans Fat can have up to two decimal places.",
        "Trans Fat cannot be greater than Saturated Fat or Total Fat.",
      ],
      layout: 4,
      description: "",
      list: [],
      items: [],
      key: "trans_fat",
      visible: true,
      required:
        output &&
        output.mandatory_nutrient &&
        output.mandatory_nutrient[0] &&
        output.mandatory_nutrient[0].total_fat > 0.5,
    },
  ],
})

export const mandatoryKeys = NutritionInformation({}).fields.map((v) => v.key)

export default NutritionInformation
