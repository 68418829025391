import classNames from "classnames"
import React, { useEffect, useState } from "react"
// @ts-ignore
import Handlebar from "handlebars/dist/cjs/handlebars"

interface FieldData {
  title: string
  key: string
  required: boolean
  validator: string | ((value: any) => any)
  error: string
  hideTitleOnActive: boolean
  readOnly: boolean
  maxLength: number
  infoComponent: React.ComponentType<any>
  disabled: boolean
}

interface QkFormTextFieldProps {
  data: FieldData
  handleChange: (value: string) => void
  values: {
    [key: string]: string
  }
  index?: number
}

export default function QkFormTextField(props: QkFormTextFieldProps) {
  const [error, setError] = useState("")
  const [active, setActive] = useState(
    props.values[props.data.key] ? true : false,
  )
  const [showPlaceholder, setShowPlaceholder] = useState(true)

  const placeholder = Handlebar.compile(
    `${props.data.title} ${props.data.required ? "*" : ""}`,
  )({ index: (props.index ?? 0) + 1 })

  const handleBlur = () => {
    // Check if validator key is present
    if (props.data.validator === undefined || props.data.validator === "")
      return

    let validated = true
    // Check for validator condition
    if (typeof props.data.validator === "string") {
      validated = eval(props.data.validator)
    } else if (typeof props.data.validator === "function") {
      validated = props.data.validator(props.values)
    } else {
      validated = props.data.validator
    }

    // Set or remove error message
    if (validated) setError("")
    else setError(props.data.error ?? "Please check your provided value")
  }

  useEffect(() => {
    setShowPlaceholder(!props?.data?.hideTitleOnActive && active)
    setActive(Boolean(props?.values[props?.data.key]))
  }, [props?.data, props?.values, active])

  const determineMaxLength = () => {
    if (props.data.key === "name" && props.data.title.includes("Ingredient")) {
      return 80
    }

    // Menu Label Recipe Name
    if (props.data.key === "name" && props.data.title.includes("Recipe Name")) {
      return 120
    }

    if (
      props.data.key === "ingredient" &&
      props.data.title.includes("Ingredient")
    ) {
      return 450
    }
    if (props.data.key === "other_information") {
      return 400
    }
    return 50
  }

  return (
    <label className={``}>
      {" "}
      {showPlaceholder && (
        <p className="absolute mt-[-15px] text-textgray bg-[white] ml-[10px] px-[5px] py-0 scale-75 z-10">
          {placeholder}
        </p>
      )}
      <input
        type="text"
        maxLength={determineMaxLength()}
        placeholder={placeholder}
        className={classNames(
          `relative z-0 shadow-sm border w-full rounded-lg px-4 leading-8  placeholder:text-textgraylight focus:ring-black focus:border-none ${
            props.data.disabled ? "cursor-not-allowed opacity-50" : ""
          }`,
          error ? "border-red-500 border-2" : "border-bordergray",
        )}
        name={props.data.key}
        value={props.values[props.data.key] ?? ""}
        onChange={(e) => {
          if (e.target.value !== "") setActive(true)
          else setActive(false)
          props.handleChange(e.target.value)
        }}
        onBlur={handleBlur}
        readOnly={props.data.readOnly}
        disabled={props.data.disabled}
      />
      {error && <span className="text-xs text-red-500">{error}</span>}
      {props?.data?.infoComponent && <props.data.infoComponent />}
    </label>
  )
}
