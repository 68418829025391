import React from "react"
import { FieldType } from "../constants/enum"
import QkCheckbox from "./QkCheckbox"
import QkDropdown from "./QkDropdown"
import QkDropup from "./QkDropup"
import QkFileUpload from "./QkFileUpload"
import { GRID_LAYOUT } from "../constants/layoutV2"
import QkAutoSuggest from "./QkAutoSuggest"
import QkHelp from "./QkHelp"
import QkSearch from "./QkSearch"
import QkRadio from "./QkRadio"
import QkMultiLine from "./QkMultiLine"
import QkTextButton from "./QkTextButton"
import QkButton from "./QkButton"
import QkFormTextField from "./QkFormTextField"
import QkTextArea from "./QkTextArea"
import QkFormNumberField from "./QkFormNumberField"
import QkInputTable from "./QkInputTable"
import QkModal from "./QkModal"
import QkMultiInput from "./QkMultiInput"
import QkFieldInfo from "./QkFieldInfo"
import QkDate from "./QkDate"
import QkDateTime from "./QkDateTime"
import QkDropdownSearch from "./QkDropdownSearch"
import QkCategory from "./QkCategory"
import AnchorElTooltips from "../common/AnchorElToolTips/AnchorElToolTips"
import QkDropdownFlag from "./QkDropdownFlag"
import QkAdditiveAutoSuggest from "./QkAdditiveAutoSuggest"
import QkUsaAdditiveAutoSuggest from "./QkUsaAdditiveAutoSuggest"
import QkRecipeDropdownSearch from "./QkRecipeDropdownSearch"

function mapListForAdditives(props, uuid, values) {
  const schemaData = props.data.schemaData

  if (schemaData?.additives_ins_values) {
    const list = schemaData?.additives_ins_values[uuid]?.map((obj) => {
      return {
        key: `${obj.ins} ${obj.name}`,
        value: `${obj.ins} ${obj.name}`,
      }
    })
    return {
      ...props.data,
      list,
    }
  }
  return props.data
}

function Input(props) {
  switch (props.data.type) {
    case FieldType.DROPDOWN:
      return <QkDropdown {...props} />
    case FieldType.DROPUP:
      return <QkDropup {...props} />
    case FieldType.DROPDOWNSEARCH:
      return <QkDropdownSearch {...props} />
    case FieldType.RECIPE_DROPDOWN_SEARCH:
      return <QkRecipeDropdownSearch {...props} />
    case FieldType.BUTTON:
      return <QkButton {...props} />
    case FieldType.TEXT_BUTTON:
      return <QkTextButton {...props} />
    case FieldType.AUTOSUGGEST:
      if (
        props.data.key === "ins_number" ||
        props.data.key === "additive_value_show"
      ) {
        const data = mapListForAdditives(props, props.uuid, props.values)
        return (
          <QkAutoSuggest
            data={data}
            handleChange={props.handleChange}
            values={props.values}
          />
        )
      } else return <QkAutoSuggest {...props} />
    case FieldType.USA_ADDITVE_AUTOSUGGEST:
      if (
        props.data.key === "ins_number" ||
        props.data.key === "additive_value_show"
      ) {
        const data = mapListForAdditives(props, props.uuid, props.values)
        return (
          <QkAutoSuggest
            data={data}
            handleChange={props.handleChange}
            values={props.values}
          />
        )
      } else return <QkUsaAdditiveAutoSuggest {...props} />
    case FieldType.ADDITIVE_AUTOSUGGEST:
      if (
        props.data.key === "ins_number" ||
        props.data.key === "additive_value_show"
      ) {
        const data = mapListForAdditives(props, props.uuid, props.values)
        return (
          <QkAdditiveAutoSuggest
            data={data}
            handleChange={props.handleChange}
            values={props.values}
          />
        )
      } else return <QkAdditiveAutoSuggest {...props} />
    case FieldType.SEARCH:
      return <QkSearch {...props} />
    case FieldType.CUSTOM:
      return <props.data.component {...props} />
    case FieldType.INPUT_TABLE:
      return (
        <QkInputTable
          handleChangeParent={props.handleChange}
          values={props.values[props.data.key]}
          data={props.data.component}
          repeatable={props.data.repeatable}
          draggable={props.data.draggable}
        />
      )
    case FieldType.MODAL:
      return <QkModal data={props.data.component} parentData={props.data} />
    case FieldType.FILE:
      return <QkFileUpload {...props} />
    case FieldType.TEXT:
      return <QkFormTextField {...props} />
    case FieldType.LONG_TEXT:
      return <QkTextArea {...props} />
    case FieldType.NUMBER:
      return <QkFormNumberField {...props} />
    case FieldType.RADIO:
      return <QkRadio {...props} />
    case FieldType.CHECKBOX:
      return <QkCheckbox {...props} />
    case FieldType.MULTI_INPUT:
      return (
        <QkMultiInput
          handleChangeParent={props.handleChange}
          values={props.values[props.data.key]}
          data={props.data.component}
          parentData={props.data}
          repeatable={props.data.repeatable}
          hide_add_more={props.data.hide_add_more}
          draggable={props.data.draggable}
        />
      )
    case FieldType.INFO:
      return <QkFieldInfo {...props} />
    case FieldType.MULTI_LINE:
      return <QkMultiLine {...props} />
    case FieldType.DISPLAY_TEXT:
      return (
        <div className="flex flex-col">
          <p className={props.data.styles}>{props.data.text}</p>
          {props.data.underline ? (
            <div className="h-1 mt-1 w-12 bg-primary" />
          ) : (
            ""
          )}
        </div>
      )
    case FieldType.BLANK:
      return <div />
    case FieldType.MESSAGE:
      if (props.data?.title && props.data?.title?.length > 0) {
        return (
          <div>
            <em>{props.data.title}</em>
          </div>
        )
      }
    case FieldType.DATE:
      return <QkDate {...props} />
    case FieldType.DATETIME:
      return <QkDateTime {...props} />
    case FieldType.CATEGORY:
      return <QkCategory {...props} />
    case FieldType.DROPDOWNFLAG:
      return <QkDropdownFlag {...props} />
    default:
      return (
        <input
          type="text"
          className="shadow-sm border border-gray-200 w-full font-light rounded-lg px-4"
          name={props.data.key}
        />
      )
  }
}

export default function QkFieldInput(props) {
  let { visible } = props.data
  if (typeof props.data.visible === "string") {
    let output = props.values
    visible = eval(props.data.visible)
  }
  if (!props.values[props.data.key] && props.data.default) {
    props.handleChange(props.data.default)
  }

  let layout = GRID_LAYOUT[props.data.layout] ?? GRID_LAYOUT.DEFAULT

  if (visible === false) return <></>
  return (
    <div className={`${props.data.customStyle} ${layout}`}>
      {props.data.title && props.data.displayTitle && (
        <div className="inline-flex items-center mb-2">
          <span className="text-lg mr-1 font-semibold">{props.data.title}</span>
          {props.data.help && <QkHelp helpText={props.data.help} />}
        </div>
      )}
      {props?.data?.disabled ? (
        <AnchorElTooltips
          country={props?.values?.country || props?.data.country}
          disable_text={props?.data?.disable_text}
          component={<Input {...props} disabled={props?.data?.disabled} />}
        />
      ) : (
        <Input {...props} />
      )}
      {props.data.warning && (
        <p className="text-xs text-orange-600">{props.data.warning}</p>
      )}
    </div>
  )
}

//diplaytitle missing in props
