import {
  GET_FAQS_REQUEST,
  GET_FAQS_SUCCESS,
  GET_FAQS_FAILURE,
} from "./faqTypes"
import apis from "../../services/ApiServices"

export const getFaqsRequest = () => {
  return {
    type: GET_FAQS_REQUEST,
  }
}

export const getFaqsSuccess = (faqs) => {
  return {
    type: GET_FAQS_SUCCESS,
    payload: faqs,
  }
}

export const getFaqsFailure = () => {
  return {
    type: GET_FAQS_FAILURE,
  }
}

export const getFaqs = () => {
  return async (dispatch) => {
    dispatch(getFaqsRequest())
    const type = "faq"
    const faqResponse = await apis.getAllRecipeContentByType(type)
    if ("success" in faqResponse) {
      let faqs = faqResponse.result.items
      let faqs_output = {}
      let categories = faqs.map((faq) => faq.details.category)
      let unique_categories = Array.from(new Set(categories))
      unique_categories.forEach((category) => {
        faqs_output[category] = []
      })
      faqs.forEach((faq) => {
        let faq_obj = {
          question: faq.details.question,
          answer: faq.details.answer,
        }
        faqs_output[faq.details.category].push(faq_obj)
      })
      dispatch(getFaqsSuccess(faqs_output))
    } else {
      dispatch(getFaqsFailure())
    }
  }
}
