import React, { useEffect } from "react"
import { Route, Redirect, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Auth } from "aws-amplify"
import { userLogin } from "../Redux"
import { getUserDetailsFromToken } from "../services/ApiServicesv2"

const PrivateRoute = ({ component: Component, ...rest }) => {
  const router = useHistory()
  const dispatch = useDispatch()
  const [authenticated, setAuthenticated] = React.useState(false)
  const [mounted, setMounted] = React.useState(false)
  const resetPassword = useSelector((state) => state.login.resetPassword)

  useEffect(async () => {
    const user_details = localStorage.getItem("persist:root")
    const { login } = JSON.parse(user_details)
    if (user_details && login) {
      const login_details = JSON.parse(login)

      if (login_details?.lbUserId?.length <= 0) {
        router.push("/")
        return
      }

      if (login_details) {
        const updated_login_details = {}
        updated_login_details.user_details = login_details
        if (!login_details.lbUserId) {
          await userLogin(dispatch, updated_login_details)
        }
        setAuthenticated(true)
      }

      // })
      // .finally(() => {
      setMounted(true)
    } else {
      const user_details = await getUserDetailsFromToken()

      const new_user_details = {}

      new_user_details.login = user_details.data.user_details
      console.log("updating from pricaterout")
      setTimeout(() => {
        localStorage.setItem("persist:root", JSON.stringify(new_user_details))
      }, 5000)

      const login_details = JSON.parse(
        localStorage.getItem("persist:root") ?? "{}",
      )

      const { login } = login_details

      if (login_details?.lbUserId?.length <= 0) {
        router.push("/")
        return
      }

      if (login_details) {
        const updated_login_details = {}
        updated_login_details.user_details = login_details
        if (!login_details.lbUserId) {
          await userLogin(dispatch, updated_login_details)
        }
        setAuthenticated(true)
      }

      setMounted(true)
    }
  })

  if (!mounted) {
    return <div />
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (resetPassword) return <Redirect to="/reset-password" />
        if (!authenticated) return <Redirect to="/login" />
        return <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
