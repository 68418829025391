import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  CLEAR_LOGIN_ERRORS,
  USER_BUSINESS_MAPPING_CREATED,
  SET_UNCONFIRMED_EMAIL,
  UPDATE_NEW_BUSINESS,
  USER_BUSINESS_TYPE,
  SET_USER_ALL_BUSINESSES,
} from "./loginTypes"
import APIS from "../../services/ApiServices"
import { Auth } from "aws-amplify"
import { getCurrentPPFPlanFeatures } from "../../helpers/helper"
import { setPPFPlanFeatures } from "../UI/UIActions"
import {
  getPermissions,
  getUserRole,
  getBusinessByUserId,
  getUsersBusiness,
} from "../../services/ApiServicesv2"
import { getProfile } from "../Profile/profileActions"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"

export const loginRequest = () => {
  return {
    type: USER_LOGIN_REQUEST,
  }
}

export const loginSuccess = (user_info) => {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: user_info,
  }
}

export const loginFailure = (error_message) => {
  return {
    type: USER_LOGIN_FAILURE,
    payload: error_message,
  }
}

export const clearLoginErrors = () => {
  return {
    type: CLEAR_LOGIN_ERRORS,
  }
}

// Finds the best available business for the user based on page url
async function findCurrentBusiness(businesses) {
  const url = window.location.pathname
  let business_id = null
  let business_type = null

  if (url.startsWith("/ppf")) {
    business_id = businesses.find((business) => {
      return business.type === "Manufacturer"
    })?.id
    business_type = "Manufacturer"
  } else if (url.startsWith("/ml")) {
    business_id = businesses.find((business) => {
      return business.type !== "Manufacturer"
    })?.id
    business_type = "Restaurant"
  }

  // If no business found, use the first business
  if (!business_id) {
    business_id = businesses[0]?.id
    business_type =
      businesses[0]?.type === "Manufacturer" ? "Manufacturer" : "Restaurant"
  }

  return { business_type, business_id }
}

export async function userLogin(dispatch, login_response) {
  // return async function login(login_response) {
  dispatch(loginRequest())
  if (login_response) {
    // const lbUserId = login_response.user_detail.lbUserid
    // const email = login_response.user_detail.email
    // const name = login_response.user_detail.first_name
    // const phoneNumber = login_response.user_detail.phone_number
    // const cognitoUserId = login_response.user_detail.lbUserid

    const { lbUserId, email, first_name, phone_number } =
      login_response.user_details

    const user_info = {
      email,
      name: first_name,
      phoneNumber: phone_number,
      lbUserId,
      cognitoUserId: lbUserId,
      businessId: "",
    }

    // const userData = await APIS.getUser(lbUserId)
    // const user_details = userData?.result?.items
    const user_businesses = await getUsersBusiness()

    // const user_businesses_response = await getBusinessByUserId(lbUserId)
    // const user_businesses_rec = await Promise.all(
    //   user_businesses_response?.result?.items?.map(async (ele) => {
    //     const businessDetails = await APIS.getBusinessDetails(ele.businessId)
    //     return (
    //       businessDetails?.result?.items?.length > 0 &&
    //       businessDetails?.result?.items[0]
    //     )
    //   }),
    // )
    // const user_businesses = user_businesses_rec.filter(
    //   (el) => typeof el === "object",
    // )
    // if (user_details.length !== 0) {
    //   if (
    //     !user_details.cognitoUserId ||
    //     user_details.cognitoUserId === "INVITED"
    //   ) {
    //     let params = {
    //       lbUserId: lbUserId,
    //       cognitoUserId: cognitoUserId,
    //       status: "verified",
    //     }
    //     await APIS.updateUser(params)
    //   }
    //   user_info.resetPassword = false
    // }

    // Load profile details to Redux
    dispatch(getProfile(lbUserId))

    if (user_businesses) {
      const { business_type, business_id } = await findCurrentBusiness(
        user_businesses.data,
      )
      user_info.businessId = business_id
      user_info.user_has_businesses = true
      user_info.allBusinesses = user_businesses.data
      user_info.allMappedBusiness = user_businesses.data
      await dispatch(setBusinessesType(business_type))

      await dispatch(setPermission(business_id, lbUserId))
    }

    await dispatch(loginSuccess(user_info))
  } else {
    const lbUserId = login_response.lbUserId
    const email = login_response.email
    const name = login_response.first_name
    const phoneNumber = login_response.phone_number
    const cognitoUserId = login_response.lbUserId
    const user_info = {
      email,
      name,
      phoneNumber,
      lbUserId,
      cognitoUserId,
      businessId: "",
      resetPassword: true,
    }
    dispatch(loginSuccess(user_info))
  }
  // else {
  //   // if (
  //   //   "code" in login_response &&
  //   //   login_response.code === "UserNotConfirmedException"
  //   // ) {
  //   //   let user_email = window.btoa(user_info.email)
  //   //   window.location.href = `/confirm-email/${user_email}`
  //   // }
  //   let error_message = login_response.message
  //   dispatch(loginFailure(error_message))
  // }

  // Set PPF Plan Features
  const features = await getCurrentPPFPlanFeatures()
  await dispatch(setPPFPlanFeatures(features))
  return Promise.resolve()
}
//}

/**
 * This function will fetch the permission of the current logged in user and sets it to reduc
 * @param {*} businessId
 * @param {*} lbUserId
 * @returns
 */
export const setPermission = (business_id, lbUserId) => {
  return async (dispatch) => {
    // const business_permissions = await getPermissions(business_id)
    const user_role = await getUserRole(business_id, lbUserId)

    if (!user_role || user_role.code !== 200) {
      logoutUser()
      return
    }

    if (user_role) {
      const userDetails = JSON.parse(
        window.localStorage.getItem("persist:root"),
      )

      // const local_storage_user_details = JSON.parse(
      //   window.localStorage.getItem("User_Details"),
      // )

      const existingLogin = JSON.parse(userDetails.login)

      existingLogin.role = user_role.data.role

      userDetails.login = JSON.stringify(existingLogin)
      // ;(userDetails.lbUserId = local_storage_user_details.id),
      //   (userDetails.email = local_storage_user_details.email),
      //   (userDetails.name =
      //     local_storage_user_details.first_name +
      //     " " +
      //     local_storage_user_details.last_name),
      //   (userDetails.phoneNumber = local_storage_user_details.phone_number),
      localStorage.setItem("persist:root", JSON.stringify(userDetails))

      if (business_id && user_role) {
        dispatch(setUserHasBusinesses(business_id, user_role.data.role))
      }
    }
  }
}

export const setUserHasBusinesses = (businessId, role) => {
  return {
    type: USER_BUSINESS_MAPPING_CREATED,
    payload: {
      businessId,
      role,
    },
  }
}

export const logoutUser = () => {
  return async (dispatch) => {
    await APIS.signOut()
    localStorage.clear()
    window.location.href = "/"
  }
}

export const setUnconfirmedEmail = (email) => {
  return {
    type: SET_UNCONFIRMED_EMAIL,
    payload: email,
  }
}

export const AddBusiness = (business) => {
  return {
    type: UPDATE_NEW_BUSINESS,
    payload: business,
  }
}

export const setBusinessesType = (businessType) => {
  return {
    type: USER_BUSINESS_TYPE,
    payload: businessType,
  }
}

export const setUserAllBusinesses = (businesses) => {
  return {
    type: SET_USER_ALL_BUSINESSES,
    payload: businesses,
  }
}

// export const loginUserFederated = () => {
//   return async (dispatch) => {
//     dispatch(loginRequest())
//     let response = await Auth.currentAuthenticatedUser()

//     if ("attributes" in response && "identities" in response.attributes) {
//       let lbUserId
//       let identities = JSON.parse(response.attributes.identities)
//       let provider = identities[0].providerName
//       let attributes = response.attributes
//       let userDetails = {
//         emailId: attributes.email,
//         firstName: attributes.name.split(" ")[0],
//         lastName: attributes.name.split(" ")[1],
//         cognitoUserId: response.username,
//         phoneNumber: "1234567890",
//       }

//       let users = await APIS.getUsers()
//       if (users.result.items.length > 0) {
//         users.result.items.map(async (user) => {
//           if (
//             user.cognitoUserId === response.username ||
//             user.emailId === attributes.email
//           ) {
//             lbUserId = user.lbUserId
//           }
//         })
//       }
//       if (!lbUserId) {
//         let response = await APIS.createUser(userDetails)
//         lbUserId = response.result.items[0].lbUserId
//       }
//       let phone = ""
//       let business_id = "",
//         user_info = {}

//       let user_businesses_response = await APIS.getUserBusinesses(lbUserId)
//       let user_businesses = user_businesses_response.result.items

//       user_info = {
//         email: attributes.email,
//         name: attributes.name,
//         phoneNumber: phone,
//         lbUserId: lbUserId,
//         cognitoUserId: response.username,
//         businessId: "",
//       }

//       if (user_businesses.length !== 0) {
//         // Fetch Business type
//         let businessType = await APIS.getBusinessDetails(
//           user_businesses[0].businessId,
//         )
//         let business_type = ""
//         if (businessType.result.items[0].name === "manufacturer") {
//           business_type = "Manufacturer"
//         } else {
//           business_type = "Restaurant"
//         }
//         business_id = user_businesses[0].businessId
//           ? user_businesses[0].businessId
//           : null
//         user_info.businessId = business_id
//         user_info.user_has_businesses = true
//         user_info.allBusinesses = user_businesses

//         let permission
//         let postData = {
//           business_id,
//           lbUserId,
//         }
//         let businessMapDetails = await APIS.getUserBusinessesMapping(postData)
//         if (businessMapDetails && businessMapDetails.result.items.length > 0) {
//           permission = businessMapDetails.result.items[0].permission
//         }

//         dispatch(setBusinessesType(business_type))
//         dispatch(setUserHasBusinesses(business_id, permission))
//       }
//       dispatch(loginSuccess(user_info))
//     } else {
//       let error_message = response.message
//       dispatch(loginFailure(error_message))
//     }
//   }
// }
