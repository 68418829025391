import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import HelmetComponent from "../../common/HelmetComponent/HelmetComponent"
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"
import AILabelValidationSchema from "../../schema/AiLabelValidationSchema"
import { BASE_ROUTES } from "../../constants/routes"
import Loading from "../../pages/Loading/Loading"
import QkFieldInput from "../../qkFormV2/QkFieldInput"
import DOMPurify from "dompurify"
import {
  getAllBrandsByBusinessId,
  getAllLabelsByBusinessIdV2,
  generateAiReport,
  createLabel,
  getLabelById,
  fetchCategories,
  getAllVersionArtworkOfExistingLabel,
  updateAiLabelById,
  updateAiLabel,
  createLabelReview,
} from "../../services/ApiServicesv2"
import MultiUpload from "./multiUpload"
import { fetchMessages } from "../../Utils/aiValidation/utils"
import AiLabelCategoriesModal from "../../schema/AiLabelCategoriesModal"
import CategoriesModal from "../../../src/pages/LabelMaker/CategoriesModal"
import { FieldType } from "../../../src/constants/enum"
import config from "../../../src/config"
import { ppfutils } from "../../../src/Utils/ppf"
import allowedDigitalCategories from "../../../src/schema/mapping/allowed_digital_categories.json"
import { steps } from "../../../src/test"
import PreviousArtworks from "./PreviousArtworks"

const num_levels = 4
const enabled = config.LABELING.ENABLED_CATEGORIES

const defaultDropdownValues = {
  level_1: [],
  level_2: [],
  level_3: [],
  level_4: [],
}

const defaultCategoryInfo = {
  level_1: "",
  level_2: "",
  level_3: "",
  level_4: "",
}

const EditLabel = () => {
  const router = useHistory()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState([])
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const [output, setOutput] = useState([])
  const [schemaData, setSchemaData] = useState([])
  const [isLabelSelected, setIsLabelSelected] = useState(false)
  const [labelData, setLabelData] = useState({
    label_id: "",
    product_name: "",
    brand_id: "",
    category: "",
    product_code: "",
    review_status: "",
  })
  const [images, setImages] = useState([])
  const [supportDocument, setSupportDocument] = useState([])

  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false)
  const [current_category_info, set_current_category_info] = useState({
    ...defaultCategoryInfo,
  })
  const [dropdown_values, set_dropdown_values] = useState({
    ...defaultDropdownValues,
  })
  let [enabledCategories, setEnabledcategory] = useState({})
  const [createLabelLoad, setCreateLabelLoad] = useState(false)
  const [categoriesData, setCategoriesData] = useState([])
  const [previousArtworksData, setPreviousArtworksData] = useState([])
  const businessId = useSelector((state) => {
    return state.login.active_business
  })

  const foodCategories = useSelector(
    (state) => state.categories.food_categories,
  )

  const userId = useSelector((state) => {
    return state.login.lbUserId
  })

  const handleLabelChange = (selectedLabelId) => {
    const selectedLabel = schemaData.labels.find(
      (label) => label.key === selectedLabelId,
    )
    if (selectedLabel) {
      setOutput({
        ...output,
        label_id: selectedLabel.raw.title,
        product_name: selectedLabel.raw.title,
        brand_id: selectedLabel.raw.brand_id,
        category: selectedLabel.raw.label_json.category.description,
      })
      setLabelData({
        label_id: selectedLabel.raw.id || selectedLabel.raw.label_id,
        product_name: selectedLabel.raw.title,
        brand_id: selectedLabel.raw.brand_id,
        category: selectedLabel.raw.category,
      })
      setIsLabelSelected(true)
    }
  }

  const handleChange = (key, valueUnsanitized) => {
    let value =
      typeof valueUnsanitized === "string"
        ? DOMPurify.sanitize(valueUnsanitized)
        : valueUnsanitized
    if (isLabelSelected === false) {
      setOutput({
        ...output,
        [key]: value,
      })

      setLabelData({
        ...labelData,
        [key]: value,
      })
    }

    if (key === "category") {
      setIsCategoryModalOpen(true)
      setOutput({
        ...output,
        category: value,
      })
    }
  }

  const handleSave = async () => {
    if (labelData) {
      const formData = new FormData()

      formData.append("category", output.category?.category_number)

      formData.append("product_name", labelData.product_name)

      formData.append("brand_id", labelData.brand_id)

      formData.append("product_code", labelData?.product_code)

      const is_retail = output.is_retail === "Retail Product" ? true : false

      formData.append("is_retail", is_retail)

      for (let image of images) {
        formData.append("images", image)
      }

      if (supportDocument) {
        for (let image of supportDocument) {
          formData.append("support_document", image.file)
        }
      }

      setIsLoading(true)

      const ai_report = await generateAiReport(formData)

      if ("errors" in ai_report) {
        toast.error(ai_report.errors)

        setIsLoading(false)

        return
      } else {
        const user_id = userId

        const business_id = businessId

        const selected_label_category = categoriesData.filter(
          (category) =>
            category.category_number === output.category.category_number,
        )

        const modified_response = {
          id: id,
          business_id,
          category: selected_label_category[0].id || output?.category?.id,
          brand_id: labelData.brand_id,
          product_name: labelData.product_name,
          product_code: labelData.product_code,
          ocr_text: ai_report.ocr_text,
          report: {
            ...ai_report?.report,
            is_retail: is_retail,
          },
          structured_obj: ai_report.structured_obj,
          images: ai_report.images,
          score: ai_report.score,
        }

        const result = await updateAiLabel(modified_response)

        if (result.status === "success") {
          toast.success("Label updated Successfully")

          const {
            report,
            score,
            product_name,
            images: imageUrls,
            label_id,
            version,
          } = result.data[0]

          setIsLoading(false)

          const payload = {
            label_id: label_id,
            type: "ai_label",
            invitee: [userId],
            version: version,
          }

          await createLabelReview(payload)

          router.push(
            `${BASE_ROUTES.PPF}/ai-label-validation/${label_id}/${version}`,
          )
        } else {
          toast.error(result.message)
          setIsLoading(false)
        }
      }
    }
  }

  const fetchLabelData = async (id) => {
    try {
      const response = await getLabelById(id)

      const labelData = response?.data

      const currentLabelCategory = categoriesData?.filter(
        (category) => category?.id === labelData?.category,
      )

      let is_retail

      if (
        labelData &&
        labelData.report &&
        labelData.report.is_retail &&
        labelData.report.is_retail.length > 0
      ) {
        is_retail === true ? "Retail Product" : "Non-Retail Product"
      } else {
        is_retail = "Retail Product"
      }

      setOutput({
        label_id: labelData.label_id,
        product_name: labelData.product_name,
        brand_id: labelData.brand_id,
        category: currentLabelCategory[0],
        product_code: labelData?.product_code,
        images: labelData.images,
        review_status: labelData.review_status,
        is_retail: is_retail || "Retail Product",
      })

      setLabelData({
        label_id: labelData.id,
        product_name: labelData.product_name,
        brand_id: labelData.brand_id,
        category: currentLabelCategory[0],
        product_code: labelData?.product_code,
        images: labelData.images,
        review_status: labelData.review_status,
        is_retail: is_retail || "Retail Product",
      })

      const existing_artworks = await getAllVersionArtworkOfExistingLabel(id)

      setPreviousArtworksData(existing_artworks.data)

      setIsLabelSelected(true)
    } catch (error) {
      toast.error("Failed to Fetch Previous Artworks")
    }
  }

  useEffect(() => {
    if (id) {
      fetchLabelData(id)
    }
  }, [id, categoriesData])

  const getBrands = async () => {
    try {
      const brands = await getAllBrandsByBusinessId(businessId)
      setSchemaData((prev) => ({
        ...prev,
        brands: brands?.data?.map((v) => ({
          key: v.id,
          value: v.name,
          raw: {
            ...v,
          },
        })),
      }))
    } catch (error) {}
  }

  const getPpfLabelsAndData = async () => {
    try {
      const all_labels = await getAllLabelsByBusinessIdV2(businessId)

      setSchemaData((prev) => ({
        ...prev,
        labels: all_labels.data.map((label) => ({
          key: label.id,
          value: label.title,
          raw: {
            ...label,
          },
        })),
      }))
    } catch (error) {}
  }

  const loadInput = async () => {
    await getBrands()
    await getPpfLabelsAndData()
  }

  useEffect(() => {
    loadInput()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchMessages()
        setMessages(data)
      } catch (error) {
        toast.error("Failed to fetch messages.")
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const getCategories = async () => {
      try {
        const data = await fetchCategories()
        setCategoriesData(data)
      } catch (error) {
        toast.error("Failed to load categories")
      }
    }

    getCategories()
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length)
    }, 4000)
    return () => clearInterval(interval)
  }, [messages])

  function closeCategoriesModal() {
    setIsCategoryModalOpen(false)
    set_dropdown_values({ ...defaultDropdownValues })
    set_current_category_info({ ...defaultCategoryInfo })
  }

  const getCategory = (current_category_info) => {
    let last_filled = null

    let keys = Object.keys(current_category_info)

    for (let i = 0; i < keys.length; i++) {
      if (
        current_category_info[keys[i]] === "" ||
        current_category_info[keys[i]] === "0"
      ) {
        last_filled = i - 1
        break
      }
    }

    if (last_filled === null) {
      last_filled = keys.length - 1
    }

    let category_description = ""
    let current_categories = enabledCategories[keys[last_filled]].filter(
      (category) =>
        category.level_value === current_category_info[keys[last_filled]],
    )

    if (current_categories.length > 0) {
      category_description = current_categories[0].category_value
    }

    return {
      category: current_category_info[keys[last_filled]],
      key: keys[last_filled],
      description: category_description,
    }
  }

  const handleCreate = async (e) => {
    setCreateLabelLoad(true)

    if (!current_category_info.level_1) {
      toast.error("Category is required!")
      setCreateLabelLoad(false)
      return
    }

    const lastDisabledCategoryLevel = Object.keys(dropdown_values)?.find(
      (key) => dropdown_values?.[key]?.length === 0,
    )
    const [levelText, levelNumber] = lastDisabledCategoryLevel?.split("_") || [
      "",
      "",
    ]
    const enabledLastCategory = lastDisabledCategoryLevel
      ? `${levelText}_${Number(levelNumber) - 1}`
      : null

    if (!lastDisabledCategoryLevel && !current_category_info.level_4) {
      toast.error("Select sub-categories to proceed")
      setCreateLabelLoad(false)
      return
    }
    if (enabledLastCategory && !current_category_info[enabledLastCategory]) {
      toast.error("Select sub-categories to proceed")
      setCreateLabelLoad(false)
      return
    }

    let currentCategory = getCategory(current_category_info)
    let filtered_steps = ppfutils.applyFilters(steps, currentCategory.category)

    let categoryObject = {
      category: currentCategory?.description,
      category_info: current_category_info,
    }

    let categoryUuid = categoriesData?.filter((category) => {
      if (category?.category_number === currentCategory?.category) {
        setOutput({
          ...output,
          category: category,
        })
      }
    })

    setCreateLabelLoad(false)
  }

  useEffect(() => {
    const res = ppfutils.filterCategories(foodCategories, enabled, "STANDARD")
    if (res) {
      setEnabledcategory(res)
      set_dropdown_values({
        level_1: [...res?.level_1],
        level_2: [...res?.level_2],
        level_3: [...res?.level_3],
        level_4: [...res?.level_4],
      })
    }
  }, [isCategoryModalOpen])

  const currentCategoryOptionChange = (selected_value, level) => {
    let selected = selected_value
    let current_category_info1 = { ...current_category_info }
    let food_categories = enabledCategories
    let max_level_value = "level_" + num_levels
    let target_level = parseInt(level.split("_")[1]) + 1

    let target_level_value = "level_" + target_level
    let filtered_values, dropdown_values1

    current_category_info1[level] = selected
    if (level !== max_level_value) {
      filtered_values = food_categories[target_level_value].filter((obj) =>
        obj.level_value.startsWith(selected + "."),
      )
      // Only allow specific categories for digital label
      filtered_values = filtered_values.filter((v) => {
        for (let i = 0; i < allowedDigitalCategories.length; i++) {
          if (allowedDigitalCategories[i].includes(v.level_value)) return true
        }

        return false
      })

      dropdown_values1 = { ...dropdown_values }
      dropdown_values1[target_level_value] = [...filtered_values]

      for (let i = target_level + 1; i <= num_levels; i++) {
        let level_value = "level_" + i
        dropdown_values1[level_value] = []
        current_category_info1[level_value] = ""
      }

      set_current_category_info(current_category_info1)
      set_dropdown_values(dropdown_values1)
    } else {
      set_current_category_info(current_category_info1)
    }
  }

  const removeSubsequentLevelsData = (level) => {
    if (level > 4) {
      return
    }
    setOutput((prev) => ({
      ...prev,
      [`level_${level}`]: "",
    }))
    setOutput((prev) => ({
      ...prev,
      [`category_level_${level}`]: "",
    }))
    set_current_category_info((info) => ({
      ...info,
      [`level_${level}`]: "",
    }))
    if (level < 4) {
      set_dropdown_values((info) => ({
        ...info,
        [`level_${level + 1}`]: [],
      }))
    }
    removeSubsequentLevelsData(level + 1)
  }

  const handleCategoryChange = (key, value) => {
    if (key.split("_")[0] === "category") {
      currentCategoryOptionChange(value, key.replace("category_", ""))
      setOutput((prev) => ({
        ...prev,
        [key.replace("category_", "")]: value,
      }))
    }
    setOutput((prev) => ({
      ...prev,
      [key]: value,
    }))

    removeSubsequentLevelsData(Number(key?.split("_")[2]) + 1 || 5)
  }

  const handleModalAction = async () => {
    try {
      handleCreate()
    } catch (err) {
      toast.error("Category could not be created.")
    }
    setIsCategoryModalOpen(false)
  }

  useEffect(() => {
    // Set the initial currentMessageIndex to a random value
    setCurrentMessageIndex(Math.floor(Math.random() * messages.length))

    const interval = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length)
    }, 8000)

    return () => clearInterval(interval)
  }, [messages])

  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            left: `55%`,
            top: `50%`,
            transform: "translate(-50%, -50%)",
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
            fontFamily: "Arial, sans-serif",
            color: "#333",
          }}
        >
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              margin: "0",
              marginBottom: "20px",
            }}
          >
            {messages[currentMessageIndex]}
          </p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <svg
              style={{
                animation: "spin 2s linear infinite",
                width: "50px",
                height: "50px",
              }}
              viewBox="0 0 50 50"
            >
              <circle
                style={{
                  stroke: "#ccc",
                  fill: "none",
                  strokeWidth: "4",
                  strokeLinecap: "round",
                  cx: "25",
                  cy: "25",
                  r: "20",
                }}
              />
              <circle
                style={{
                  stroke: "grey",
                  fill: "none",
                  strokeWidth: "4",
                  strokeLinecap: "round",
                  strokeDasharray: "125",
                  strokeDashoffset: "125",
                  cx: "25",
                  cy: "25",
                  r: "20",
                  transformOrigin: "50% 50%",
                  animation: "dash 1.5s ease-in-out infinite",
                }}
              />
            </svg>
            <style>
              {`
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
                @keyframes dash {
                  0% {
                    stroke-dashoffset: 125;
                  }
                  50% {
                    stroke-dashoffset: 25;
                    transform: rotate(135deg);
                  }
                  100% {
                    stroke-dashoffset: 125;
                    transform: rotate(450deg);
                  }
                }
              `}
            </style>
          </div>
        </div>
      ) : (
        <div>
          <HelmetComponent pageTitle={"AI Label Validation"} />
          <div className="relative font-inter w-full md:pr-40">
            <div className="text-2xl font-bold my-3">AI Label Validation</div>
            <Breadcrumbs
              linksRef={[
                {
                  page: "Dashboard",
                  link: `${BASE_ROUTES.PPF}/user-dashboard`,
                },
                {
                  page: "AI Label Validation",
                  link: `${BASE_ROUTES.PPF}/ai-label-validation`,
                },
                {
                  page: "Validation",
                  link: `${BASE_ROUTES.PPF}/ai-label-validation/validate`,
                },
              ]}
            />

            <div className="mt-4">
              {AILabelValidationSchema(schemaData, output)?.map(
                (section, index) => (
                  <React.Fragment key={index}>
                    <div className="bg-white border mt-4 p-5 rounded-lg">
                      <p className="text-lg col-span-12 mb-3 font-bold">
                        {section.name}
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {section?.fields?.map((field) => {
                          if (field.type === "ai_file") {
                            return (
                              <div key={field.key} className="md:col-span-2">
                                <MultiUpload
                                  title={field.title}
                                  onChange={(files) => setImages(files)}
                                />
                              </div>
                            )
                          }

                          return (
                            <div
                              key={field.key}
                              className="md:col-span-1 cursor-not-allowed"
                            >
                              <QkFieldInput
                                data={field ?? {}}
                                values={output ?? {}}
                                handleChange={
                                  field.key === "label_id"
                                    ? handleLabelChange
                                    : (value) => handleChange(field.key, value)
                                }
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <br />
                  </React.Fragment>
                ),
              )}
            </div>

            <div className="bg-white border mt-4 mb-8 p-5 h-60 rounded-lg">
              <p className="text-lg col-span-12 mb-2 font-bold">
                Previous Artworks
              </p>
              <div className="overflow-y-scroll no-scrollbar h-40">
                {previousArtworksData.map((artwork, index) => (
                  <PreviousArtworks
                    key={index}
                    version={artwork.version}
                    status={artwork.review_status}
                    files={artwork.images}
                  />
                ))}
              </div>
            </div>

            <div className="flex justify-start">
              <button
                disabled={labelData.review_status === "Approved"}
                className={`${
                  labelData.review_status === "Approved"
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-primaryyellow hover:bg-yellow-500"
                } text-base text-black py-3 px-4 font-bold rounded-lg inline-flex items-center`}
                onClick={handleSave}
              >
                Validate Artwork
              </button>
            </div>
          </div>
        </div>
      )}
      {isCategoryModalOpen && (
        <CategoriesModal
          key={"Categories Selection"}
          data={{
            title: "New Ingredient Request",
            type: FieldType.MODAL,
            component: AiLabelCategoriesModal(output, dropdown_values),
            key: "categories_selection_modal",
            required: true,
            visible: true,
            openButtonStyle:
              "bg-primaryyellow  text-base text-black text-sm py-3 px-4 font-bold rounded-lg inline-flex items-center",
            actionButtonText: "Create",
            action: handleCreate,
          }}
          values={output ?? {}}
          isOpen={isCategoryModalOpen}
          handleChange={handleCategoryChange}
          // openModal={openCategoriesModal}
          closeModal={closeCategoriesModal}
          handleAction={handleModalAction}
          loading={createLabelLoad}
        />
      )}
    </>
  )
}

export default EditLabel
