import React, { useEffect, lazy, Suspense } from "react"
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom"
import PrivateRoute from "./routers/PrivateRoute"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import reduxStore from "./Redux/store"
import Loading from "./pages/Loading/Loading"
import { useDispatch } from "react-redux"
import { navbarInactive, userLogin } from "./Redux"
import Protected from "./components/Protected/Protected"
import ContactUs from "./pages/contact-us/ContactUs"
import UserDashboard from "./components/UserDashboard"
import { LastLocationProvider } from "react-router-last-location"
import "./App.css"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import config from "./config"
import { Auth } from "aws-amplify"
import { globalContentInitialize } from "./Redux/InitializeContent/content"
import { COLORS } from "./Utils/colorPalette"
import TagManager from "react-gtm-module"
import MobilePaymentPage from "./pages/PaymentGateway/mobile"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import PPFMobilePaymentPage from "./pages/PpfPricing/mobile"
import MenuLabellingRouter from "./routers/BusinessTypeAppRouter/menuLabellingRouter"
import PPFRouter from "./routers/BusinessTypeAppRouter/ppfRouter"
import { BASE_ROUTES } from "./constants/routes"

const TEST_ROUTES_ENABLED = config.TEST_ROUTES_ENABLED[config.ENV]

const Home = lazy(() => import("./pages/home/Home"))
const DebugPage = lazy(() => import("./pages/debug"))
const SignIn = lazy(() => import("./pages/signIn/SignIn"))
const AboutUs = lazy(() => import("./pages/about-us/AboutUs"))
const Register = lazy(() => import("./pages/register/Register"))
const ForgotPassword = lazy(() =>
  import("./pages/forgotPassword/ForgotPassword"),
)
const SetPassword = lazy(() => import("./pages/setPassword/SetPassword"))
const Faqs = lazy(() => import("./pages/faqs/Faqs"))
const Contact = lazy(() => import("./pages/contacts/Contact"))
const Fssai = lazy(() => import("./pages/fssai/Fssai"))
const Error = lazy(() => import("./pages/error/Error"))
const Verification = lazy(() => import("./pages/verification/Verification"))
const UserRegistration = lazy(() =>
  import("./pages/userRegistration/UserRegistration"),
)
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"))
const Label = lazy(() => import("./pages/Label/Label"))
const Labelv2 = lazy(() => import("./pages/Labelv2/Labelv2"))
const Profile = lazy(() => import("./pages/profile/Profile"))
const NewRecipe = lazy(() => import("./pages/NewRecipe/NewRecipe"))
const BusinessProfile = lazy(() =>
  import("./pages/business-profile/BusinessProfile"),
)
const Subscriptions = lazy(() => import("./pages/Subscriptions/Subscriptions"))
const Checkout = lazy(() => import("./pages/PaymentGateway/checkout"))
const Analyzer = lazy(() => import("./pages/analyzer/content/Content"))
const PositioningAnalyzer = lazy(() =>
  import("./pages/positioning-analyzer/content/Content"),
)
const ConfirmEmail = lazy(() => import("./pages/ConfirmEmail/ConfirmEmail"))
const Blogs = lazy(() => import("./pages/Blogs/Blogs"))
const Blog = lazy(() => import("./pages/Blog/Blog"))
const TestPage = lazy(() => import("./pages/TestPage/TestPage"))

const Reports = lazy(() => import("./pages/Reports/Reports"))
const Report = lazy(() => import("./pages/Report/Report"))
const OrderSummary = lazy(() => import("./pages/OrderSummary/OrderSummary"))
const Billing = lazy(() => import("./components/Billing/Billing"))
const NewPricing = lazy(() => import("./pages/new-pricing/NewPricing"))
const GenerateDigitalLabel = lazy(() =>
  import("./pages/Generate-digital-label/GenerateDigitalLabel"),
)
const AddBrand = lazy(() => import("./pages/AddBrands/AddBrand"))
const PaymentPOC = lazy(() => import("./pages/payment/poc"))
const PaymentGateway = lazy(() => import("./pages/PaymentGateway"))
const PpfPricing = lazy(() => import("./pages/PpfPricing"))
const Success = lazy(() => import("./pages/success"))
const Cancel = lazy(() => import("./pages/error"))
const PricingModal = lazy(() => import("./pages/PaymentGateway/paymentModal"))
const PpfPricingModal = lazy(() => import("./pages/PpfPricing/paymentModal"))
const FreeEbook = lazy(() => import("./pages/FreeEbook/index.js"))

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.gold.dark,
      contrastText: COLORS.black.dark,
    },
    secondary: {
      main: COLORS.ascent.snow,
    },
    background: {
      default: COLORS.bg.white,
    },
    text: {
      primary: COLORS.black.dark,
    },
  },
  shape: {
    borderRadius: 4,
  },
  status: {
    danger: COLORS.ascent.danger,
  },
})
const Routes = () => {
  let location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    async function fetchData() {
      const login_details = JSON.parse(
        localStorage.getItem("persist:root") ?? "{}",
      )

      const { login } = login_details
      if (login) {
        const { lbUserId, email, name, phoneNumber } = JSON.parse(login)
        if (lbUserId && lbUserId?.length > 0) {
          checkLoggedIn()
        }
        dispatch(globalContentInitialize())
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    dispatch(navbarInactive())
  }, [location])

  const checkLoggedIn = async () => {
    try {
      const login_details = JSON.parse(
        localStorage.getItem("persist:root") ?? "{}",
      )

      const { login } = login_details

      const { lbUserId, email, name, phoneNumber } = JSON.parse(login)

      const user_details = {}

      user_details.user_details = {
        lbUserId,
        email,
        first_name: name,
        phone_number: phoneNumber,
      }
      await userLogin(dispatch, user_details)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Switch>
      <Route component={Home} path="/" exact />
      <Route component={DebugPage} path="/debug" exact />
      <Route component={AboutUs} path="/about-us" />
      <Route component={SignIn} path="/login" />
      <Route component={Register} path="/register" />
      <Route component={ForgotPassword} path="/forgot-password" />
      <Route component={SetPassword} path="/reset-password" />
      <Route component={Faqs} path="/faqs" />
      <Route component={Contact} path="/help-center" />
      <Route component={ContactUs} path="/contact-us" />
      <Route component={Fssai} path="/about-fssai" />
      <Route component={Verification} path="/verification/:token" />
      <Route component={NewPricing} path="/pricing" />
      <Route component={Analyzer} path="/nutrition-claim-analyzer" />
      <Route component={PositioningAnalyzer} path="/positioning-analyzer" />
      <Route component={ConfirmEmail} path="/confirm-email/:user_email" />
      <Route component={Blogs} path="/blogs" />
      <Route component={Blog} path="/blog/:slug" />
      <Route component={Reports} path="/reports" exact />
      <Route component={Report} path="/report/clean-label-report" />
      <Route component={FreeEbook} path="/free-ebook-compliant-food-label" />
      <Route
        component={NewRecipe}
        path="/merchant-recipe/:businessId/:recipeId"
      />
      <Route component={NewRecipe} path="/draft-recipe/:businessId/:recipeId" />

      {TEST_ROUTES_ENABLED && <Route component={TestPage} path="/test" />}

      <PrivateRoute
        component={() => <Protected component={UserDashboard} />}
        path="/user-dashboard"
        exact
      />
      {/** After implementation make it PrivateRoute */}
      <Route component={UserRegistration} path="/user-registration" />

      <PrivateRoute
        component={() => <Protected component={Dashboard} />}
        path="/dashboard"
        exact
      />
      <PrivateRoute
        component={() => <Protected component={Label} />}
        path="/label/:id"
        exact
      />
      <PrivateRoute
        component={() => <Protected component={Labelv2} />}
        path="/label/v2/:id"
        exact
      />

      <PrivateRoute
        component={() => <Protected component={Profile} />}
        path="/profile"
      />
      <PrivateRoute
        component={() => <Protected component={BusinessProfile} />}
        path="/restaurant-profile"
      />
      <PrivateRoute
        component={() => <Protected component={Subscriptions} />}
        path="/subscription"
      />

      <PrivateRoute
        component={() => <Protected component={Checkout} />}
        exact
        path="/checkout/:plan_id"
      />
      <PrivateRoute
        component={() => <Protected component={OrderSummary} />}
        path="/label-order"
      />
      <PrivateRoute
        component={() => <Protected component={Billing} />}
        path="/billing"
      />
      <PrivateRoute
        component={() => <Protected component={GenerateDigitalLabel} />}
        path="/digital-label/:businessId/:productId"
      />
      <PrivateRoute
        component={() => <Protected component={PaymentPOC} />}
        path="/payment/poc"
      />
      <Route
        component={PaymentGateway}
        path={`${BASE_ROUTES.MENU_LABEL}/plans`}
        exact
      />
      <Route component={PpfPricing} path={`${BASE_ROUTES.PPF}/plans`} exact />
      <Route
        component={MobilePaymentPage}
        path={`/m${BASE_ROUTES.MENU_LABEL}/plans`}
        exact
      />
      <Route
        component={PPFMobilePaymentPage}
        path={`/m${BASE_ROUTES.PPF}/plans`}
        exact
      />
      <PrivateRoute
        component={() => <Protected component={Success} />}
        path="/success"
        exact
      />
      <PrivateRoute
        component={() => <Protected component={Cancel} />}
        path="/cancel"
        exact
      />
      <Route component={PricingModal} path="/pricing-modal" />
      <Route
        component={PpfPricingModal}
        path={`${BASE_ROUTES.PPF}/pricing-modal`}
      />
      <PrivateRoute
        component={() => <Protected component={MenuLabellingRouter} />}
        path={`${BASE_ROUTES.MENU_LABEL}`}
      />
      <PrivateRoute component={PPFRouter} path={`${BASE_ROUTES.PPF}`} />
      <PrivateRoute
        component={MenuLabellingRouter}
        path={`${BASE_ROUTES.MENU_LABEL}`}
      />
      {/* Page Not Found Error */}
      <Route component={Error} path="/page-not-found" />
      <Route component={Error} />
    </Switch>
  )
}

const App = () => {
  useEffect(() => {
    process.env.NODE_ENV === "production" &&
      TagManager.initialize({ gtmId: "GTM-TPD3754" })
  }, [])
  return (
    <Provider store={reduxStore().store}>
      <PersistGate loading={null} persistor={reduxStore().persistor}>
        <BrowserRouter>
          <LastLocationProvider>
            <Suspense fallback={<Loading></Loading>}>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <ToastContainer />
                <Routes />
              </ThemeProvider>
            </Suspense>
          </LastLocationProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
