import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  CLEAR_LOGIN_ERRORS,
  USER_BUSINESS_MAPPING_CREATED,
  SET_UNCONFIRMED_EMAIL,
  UPDATE_NEW_BUSINESS,
  USER_BUSINESS_TYPE,
  SET_USER_ALL_BUSINESSES,
} from "./loginTypes"

const initialState = {
  lbUserId: "",
  loading: false,
  authenticated: false,
  userHasBusinesses: false,
  businesses: [],
  allBusinesses: [],
  error: false,
  error_message: "",
  email: "",
  name: "",
  phoneNumber: "",
  unconfirmed_email: "",
  business_type: "",
  resetPassword: false,
  role: "",
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticated: true,
        lbUserId: action.payload.lbUserId,
        cognitoUserId: action.payload.cognitoUserId,
        email: action.payload.email,
        name: action.payload.name,
        phoneNumber: action.payload.phoneNumber,
        userHasBusinesses: true,
        error: false,
        error_message: "",
        allBusinesses: action.payload.allBusinesses,
        resetPassword: action.payload.resetPassword,
      }
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        error_message: action.payload,
      }
    case CLEAR_LOGIN_ERRORS:
      return {
        ...state,
        loading: false,
        error: false,
        error_message: "",
      }
    case USER_BUSINESS_MAPPING_CREATED:
      return {
        ...state,
        userHasBusinesses: true,
        active_business: action.payload.businessId,
        role: action.payload.role,
      }
    case SET_UNCONFIRMED_EMAIL:
      return {
        ...state,
        unconfirmed_email: action.payload,
      }
    case UPDATE_NEW_BUSINESS:
      return {
        ...state,
        allBusinesses: [...state.allBusinesses, action.payload],
      }
    case USER_BUSINESS_TYPE:
      return {
        ...state,
        business_type: action.payload,
      }
    case SET_USER_ALL_BUSINESSES:
      return {
        ...state,
        allBusinesses: action.payload,
      }
    default:
      return state
  }
}

export default reducer
