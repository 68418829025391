import classNames from "classnames"
import React, { useState } from "react"

export default function QkFormTextField(props) {
  const [error, setError] = useState("")
  const [active, setActive] = useState(
    props.values[props.data.key] ? true : false,
  )
  const placeholder = `${props.data.title} ${props.data.required ? "*" : ""}`

  const handleBlur = () => {
    // Check if validator key is present
    if (props.data.validator === undefined || props.data.validator === "")
      return

    let validated = true

    // Check for validator condition
    if (typeof props.data.validator === "string") {
      let output = props.values // Do not remove this line
      let currentValue = props.values[props.data.key] // Do not remove this line

      validated = eval(props.data.validator) // Do not remove this line
    } else {
      validated = props.data.validator
    }

    // Set or remove error message
    if (validated) setError("")
    else setError(props.data.error ?? "Please check your provided value")

    // Check for max length
    if (
      props.data.maxLength &&
      props.values[props.data.key].length > props.data.maxLength
    ) {
      setError(
        `Please enter a value less than ${props.data.maxLength} characters`,
      )
    }
  }

  return (
    <label
      className={`relative ${
        props.data.disabled ? "opacity-50 cursor-not-allowed" : ""
      }`}
    >
      {" "}
      {!props.data.hideTitleOnActive && active && (
        <p className="absolute mt-[-15px] text-textgray bg-[white] ml-[10px] px-[5px] py-0 scale-75 z-10">
          {placeholder}
        </p>
      )}
      <textarea
        maxLength={props.data.maxLength ?? 300}
        rows={props.data.rows}
        placeholder={placeholder}
        className={classNames(
          `${
            props.data.disabled && "cursor-not-allowed opacity-50"
          } relative z-0 shadow-sm border w-full rounded-lg px-4 leading-8  placeholder:text-textgraylight focus:ring-black focus:border-none`,
          error ? "border-red-500 border-2" : "border-bordergray",
        )}
        name={props.data.key}
        value={props.values[props.data.key] ?? ""}
        onChange={(e) => {
          if (e.target.value !== "") setActive(true)
          else setActive(false)
          props.handleChange(e.target.value)
        }}
        disabled={props.data.disabled}
        onBlur={handleBlur}
        readOnly={props.data.readOnly}
      />
      {props.data.maxLength && (
        <div className="flex absolute bottom-0 right-0 p-2 text-gray-500">
          {props.values[props.data.key]?.length ?? 0}/{props.data.maxLength}
        </div>
      )}
      {error && <span className="text-xs text-red-500">{error}</span>}
    </label>
  )
}
