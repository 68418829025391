import awsExportsDev from "./aws-exports-dev"
import awsExportsProd from "./aws-exports-prod"

const current_env = process.env.NODE_ENV === "production" ? "PROD" : "PROD"
//const current_env = "DEV"

const config = {
  ENABLE_USERMODULE: true,
  ENV: current_env,
  LOGGING: {
    DEV: true,
    PROD: false,
  },
  TEST_ROUTES_ENABLED: {
    DEV: true,
    PROD: false,
  },
  BILLING: {
    CURRENCY_DEFAULT: "INR",
    LABEL_PRICE: 1500,
    GST: 0.18,
  },
  BLOGS: {
    DEV: {
      fetch: false,
    },
    PROD: {
      fetch: true,
    },
  },
  RAZORPAY_KEY: {
    DEV: "rzp_test_QPquiulB47Eete",
    PROD: "rzp_live_Mg42PD7TzIgFYS",
  },
  CLEAN_LABEL_REPORT_S3_KEY: {
    DEV: "clean_label_report_2021.pdf",
    PROD: "clean_label_report_2021.pdf",
  },
  AWS_EXPORTS: {
    DEV: awsExportsDev,
    PROD: awsExportsProd,
  },
  LABELING: {
    ENABLED_CATEGORIES: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "1.1",
      "1.2",
      "1.3",
      "1.4",
      "1.5",
      "1.6",
      "1.7",
      "1.8",
      "2.1",
      "2.2",
      "2.3",
      "2.4",
      "4.1",
      "4.2",
      "5.1",
      "5.2",
      "5.3",
      "5.4",
      "6.1",
      "6.2",
      "6.3",
      "6.4",
      "6.5",
      "6.6",
      "6.7",
      "6.8",
      "7.1",
      "7.2",
      "8.1",
      "8.2",
      "8.3",
      "8.4",
      "9.1",
      "9.2",
      "9.3",
      "9.4",
      "10.1",
      "10.2",
      "10.3",
      "10.4",
      "11.1",
      "11.2",
      "11.3",
      "11.4",
      "11.5",
      "11.6",
      "12.1",
      "12.2",
      "12.3",
      "12.4",
      "12.5",
      "12.6",
      "12.7",
      "12.8",
      "12.9",
      "12.1",
      "13.1",
      "13.2",
      "13.3",
      "13.4",
      "13.5",
      "13.6",
      "14.1",
      "14.2",
      "15.1",
      "15.2",
      "15.3",
      "1.1.1",
      "1.1.2",
      "1.2.1",
      "1.2.2",
      "1.3.1",
      "1.3.2",
      "1.4.1",
      "1.4.2",
      "1.4.3",
      "1.4.4",
      "1.5.1",
      "1.5.2",
      "1.6.1",
      "1.6.2",
      "1.6.3",
      "1.6.4",
      "1.6.5",
      "1.6.6",
      "1.8.1",
      "1.8.2",
      "2.1.1",
      "2.1.2",
      "2.1.3",
      "2.2.1",
      "2.2.2",
      "2.4.1",
      "4.1.1",
      "4.1.2",
      "4.2.1",
      "4.2.2",
      "5.1.1",
      "5.1.2",
      "5.1.3",
      "5.1.4",
      "5.2.1",
      "5.2.2",
      "5.2.3",
      "6.2.1",
      "6.2.2",
      "6.4.1",
      "6.4.2",
      "6.4.3",
      "6.8.1",
      "6.8.2",
      "6.8.3",
      "6.8.4",
      "6.8.5",
      "6.8.6",
      "6.8.7",
      "6.8.8",
      "7.1.1",
      "7.1.2",
      "7.1.3",
      "7.1.4",
      "7.1.5",
      "7.1.6",
      "7.2.1",
      "7.2.2",
      "7.2.3",
      "8.1.1",
      "8.1.2",
      "8.2.1",
      "8.2.2",
      "8.2.3",
      "8.3.1",
      "8.3.2",
      "8.3.3",
      "9.1.1",
      "9.1.2",
      "9.2.1",
      "9.2.2",
      "9.2.3",
      "9.2.4",
      "9.2.5",
      "9.3.1",
      "9.3.2",
      "9.3.3",
      "9.3.4",
      "10.2.1",
      "10.2.2",
      "10.2.3",
      "11.1.1",
      "11.1.2",
      "11.1.3",
      "11.1.4",
      "11.1.5",
      "11.1.6",
      "12.1.1",
      "12.1.2",
      "12.2.1",
      "12.2.2",
      "12.5.1",
      "12.5.2",
      "12.6.1",
      "12.6.2",
      "12.6.3",
      "12.6.4",
      "12.9.1",
      "12.9.2",
      "13.1.1",
      "13.1.2",
      "13.1.3",
      "14.1.1",
      "14.1.2",
      "14.1.3",
      "14.1.4",
      "14.1.5",
      "14.2.1",
      "14.2.2",
      "14.2.3",
      "14.2.4",
      "14.2.5",
      "14.2.6",
      "14.2.7",
      "1.1.1.1",
      "1.1.1.2",
      "1.2.1.1",
      "1.2.1.2",
      "1.3.2.1",
      "1.6.2.1",
      "1.6.2.2",
      "1.6.2.3",
      "1.6.4.1",
      "1.6.4.2",
      "4.1.1.1",
      "4.1.1.2",
      "4.1.1.3",
      "4.1.2.1",
      "4.1.2.2",
      "4.1.2.3",
      "4.1.2.4",
      "4.1.2.5",
      "4.1.2.6",
      "4.1.2.7",
      "4.1.2.8",
      "4.1.2.9",
      "4.1.2.10",
      "4.1.2.11",
      "4.1.2.12",
      "4.2.1.1",
      "4.2.1.2",
      "4.2.1.3",
      "4.2.2.1",
      "4.2.2.2",
      "4.2.2.3",
      "4.2.2.4",
      "4.2.2.5",
      "4.2.2.6",
      "4.2.2.7",
      "4.2.2.8",
      "6.8.4.1",
      "6.8.4.2",
      "6.8.4.3",
      "7.1.1.1",
      "7.1.1.2",
      "8.2.1.1",
      "8.2.1.2",
      "8.2.1.3",
      "8.3.1.1",
      "8.3.1.2",
      "8.3.1.3",
      "9.2.4.1",
      "9.2.4.2",
      "9.2.4.3",
      "11.1.3.1",
      "11.1.3.2",
      "12.9.2.1",
      "12.9.2.2",
      "12.9.2.3",
      "14.1.1.1",
      "14.1.1.2",
      "14.1.2.1",
      "14.1.2.2",
      "14.1.2.3",
      "14.1.2.4",
      "14.1.3.1",
      "14.1.3.2",
      "14.1.3.3",
      "14.1.3.4",
      "14.1.4.1",
      "14.1.4.2",
      "14.1.4.3",
      "14.2.3.1",
      "14.2.3.2",
      "14.2.3.3",
      "15.1",
      "15.2",
      "15.3",
      "18.1",
      "18.1.1",
      "18.1.1.1",
      "18.1.1.2",
      "18.1.1.3",
      "18.1.1.4",
      "18.1.2",
      "18.1.2.1",
      "18.1.2.2",
      "18.1.2.3",
      "18.1.3",
      "18.1.4",
      "18.1.5",
      "18.2",
      "18.2.1",
      "18.2.2",
      "18.2.3",
      "18.2.4",
    ],
    USERS: [
      {
        email: "Gautam1.sharma@reliancefoundation.org",
        limit: 5,
      },
      {
        email: "bfpcl.radhanpur@gmail.com",
        limit: 5,
      },
      {
        email: "Kirankumar.i.patel@reliancefoundation.org",
        limit: 5,
      },
      {
        email: "bayaldilip@gmail.com",
        limit: 5,
      },
    ],
    DEV: {
      ENABLE_DELETE: true,
    },
    PROD: {
      ENABLE_DELETE: false,
    },
  },
  REGION: "ap-south-1",
  USER_POOL:
    current_env === "DEV" ? "ap-south-1_R8ArRu20Y" : "ap-south-1_9dVc0JvXq",
  BRAND_LIMIT: 200,
  BUSINESS_USER_LIMIT: 7,
  ES_USER_BASE_RECIPE:
    current_env === "PROD" ? "user_base_recipes_prod" : "user_base_recipes",
  ES_USER_INGREDIENTS:
    current_env === "PROD" ? "user_ingredients_prod" : "user_ingredients",
  ES_USER_NEW_RECIPE: "user_new_recipes",
  PNL_URL: "https://www.foodlabelsolutions.com/pnl/",
  GOOGLE_MAP_API_KEY: "AIzaSyBGuM0rGvyJwUH-zOg0sHw6PQRPa1s_Rvo",
  ALLOWED_DIGITAL_USERS: [
    "neel.labelblind@gmail.com",
    "surajk.labelblind@gmail.com",
    "romit.labelblind@gmail.com",
    "rashida.vapiwala@labelblind.com",
    "partho.chakravarty@labelblind.com",
    "tech.labelblind@gmail.com",
    "satyam.labelblind@gmail.com",
    "gauri.labelblind@gmail.com",
    "sonika.labelblind@gmail.com",
    "shreya.labelblind@gmail.com",
    "zahra.labelblind@gmail.com",
    "oliviac.labelblind@gmail.com",
    "rashidavapiwala@yahoo.com",
    "oliviacrasto97@gmail.com",
    "monalisa.labelblind@gmail.com",
    "layeba.labelblind@gmail.com",
  ],
  ALLOWED_VERSION_USERS: [
    "neel.labelblind@gmail.com",
    "surajk.labelblind@gmail.com",
    "romit.labelblind@gmail.com",
    "rashida.vapiwala@labelblind.com",
    "partho.chakravarty@labelblind.com",
    "tech.labelblind@gmail.com",
    "satyam.labelblind@gmail.com",
    "gauri.labelblind@gmail.com",
    "sonika.labelblind@gmail.com",
    "shreya.labelblind@gmail.com",
    "zahra.labelblind@gmail.com",
    "oliviac.labelblind@gmail.com",
    "rashidavapiwala@yahoo.com",
    "monalisa.labelblind@gmail.com",
  ],
  ALLOWED_PERCENT_RDA_IN_DECIMAL: [
    "pardeep.bishnoi@bikanervala.com",
    "krati.aggarwal@bikano.com",
    "regulatory@bikano.com",
    "vineet.agarwal@bikano.com",
    "manish.aggarwal@bikano.com",
    "tejshri.labelblind@gmail.com",
  ],

  ALLOWED_DOWNLOAD_UTILITY_AND_COMPLIANCE_REPORT: [
    "rashida.vapiwala@labelblind.com",
    "jay.labelblind@gmail.com",
    "tech.labelblind@gmail.com",
    "shrikant.labelblind@gmail.com",
    "chetan.labelblind@gmail.com",
    "sonika.labelblind@gmail.com",
    "layeba.labelblind@gmail.com",
    "bushra.labelblind@gmail.com",
  ],
  PNL_BASE_URL: "https://prod.d2aysvyyco20hj.amplifyapp.com",
  APIENDPOINT:
    current_env === "PROD"
      ? "https://api.foodlabelsolutions.com"
      : "https://api-dev.foodlabelsolutions.com",

  // current_env === "PROD" ? "http://localhost:8000" : "http://localhost:8000",

  DISABLE_MERAQR: true,
  DISABLE_FOOD_TAGS: true,
  DISABLE_NUTRITION_GUIDE: true,
  DISABLE_INVITE_TAB: false,
}
// http://13.127.173.120:8000
export default config
