import React from "react"
import { FieldType } from "../constants/enum"
import additives from "./componentAdditives"
import ingredients from "./componentIngredients"
import fortified from "./componentFortified"
import mandatoryNutrients from "./componentNutritionInformation"
import nonMandatoryNutrients from "./componentOtherNutrientInfo"
import targetAudience from "./mapping/target_audience.json"
import { getRecipesV3 } from "../services/ApiServicesv2"
import { generateSweetners, veganDisplay } from "./helpers"
import consumerCare from "./componentConsumerCare"
import useStorageInstructions from "./componentUseStorageInstructions"
import allergens from "./mapping/allergens.json"
import niInfoValidator from "./validators/niInfoValidator"
import DragDropComponent from "../components/DragDropComponent/DragDropInput"
import PlasticIcon from "../common/PlasticIcon"
import packDimension from "./componentPackDimension"
import labelDimension from "./componentLabelDimension"
import blendedOil from "./componentBlendedOil"
import { RECIPE_TYPES } from "../Utils/constants"
import { getMDCheckboxes } from "../components/LabelPreview/mandatoryDeclaration"
import { BASE_ROUTES } from "../constants/routes"
import SelectedRecipeWidget from "../pages/LabelMaker/selected_recipe_widget"
import OrganicCertificateFromComponent from "../components/OrganicCertificationFromComponent"
import {
  batch_no,
  dateOfPkgMfg,
  dateOfUsebyExp,
  mrp,
  usp,
} from "./componentSkuDetail"
import FieldInformation from "../common/FieldInformation/FieldInformation"

const LabelMakerSchema = (schemaData, output) => {
  const ni_info_list =
    output &&
    output.PFL_RECIPE_SELECTION &&
    output.PFL_RECIPE_SELECTION.entry &&
    output.PFL_RECIPE_SELECTION.entry.id
      ? ["My Recipes", "Lab Report", "I want to fill in my own NI values"]
      : ["My Recipes", "Lab Report", "I want to fill in my own NI values"]

  return [
    {
      name: "Name of Food",
      description: "",
      analyticsEvent: "LABEL STEP 1 NAME OF FOOD VIEW EVENT",
      tags: ["standard", "digital"],
      fields: [
        {
          title: "Search from My Recipes",
          type: FieldType.SEARCH,
          reference: "",
          validator: "",
          description: "",
          layout: 12,
          list: [],
          items: [],
          key: "PFL_RECIPE_SELECTION",
          defaultValue: "",
          searchApi: getRecipesV3,
          searchParams: {
            limit: 5,
            business_id: schemaData.businessId,
            type: RECIPE_TYPES.NEW_RECIPE,
          },
          mapper: (res) => [
            {
              indexName: "Recipes",
              data: res?.data?.map((recipe) => ({ ...recipe, id: recipe._id })),
            },
          ],
          selectedRender: SelectedRecipeWidget,
          required: false,
          visible: true,
        },
        {
          title: "Select Brand",
          type: FieldType.DROPDOWN,
          validator: "",
          description: "",
          layout: 6,
          list: schemaData.brands,
          items: [],
          key: "brandId",
          disabled: output["PFL_RECIPE_SELECTION"],
          disable_text: "Default brand from selected recipe",
          visible: true,
          required: true,
        },
        {
          title: " ",
          type: FieldType.TEXT_BUTTON,
          validator: "",
          description: "",
          layout: 6,
          list: [],
          items: [],
          key: "Add Brand",
          visible: true,
          help: "",
          link: `${BASE_ROUTES.PPF}/add-brand`,
        },
        {
          title: "Food Name",
          type: FieldType.AUTOSUGGEST,
          layout: 6,
          description: "",
          list: schemaData.food_names ?? [],
          items: [],
          key: "food_name",
          visible: true,
          required: true,
          dropdownIcon: true,
          maxLength: 50,
          infoComponent:
            (output?.category?.category === "14.1.2.1" ||
              output?.category?.category === "14.1.2.2") &&
            output?.food_name?.includes("100%") &&
            FieldInformation,
        },
        {
          title: "Type / Flavour / Class",
          type: FieldType.AUTOSUGGEST,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: schemaData.food_types ?? [],
          items: [],
          key: "flavour",
          visible: true,
          dropdownIcon: schemaData.food_types?.length > 0 ? true : false,
          infoComponent:
            (output?.category?.category === "14.1.2.1" ||
              output?.category?.category === "14.1.2.2") &&
            output?.flavour?.includes("100%") &&
            FieldInformation,
        },
        {
          title: "Other Information",
          type: FieldType.TEXT,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "other_information",
          visible: true,
          maxLength: 300,
          infoComponent:
            (output?.category?.category === "14.1.2.1" ||
              output?.category?.category === "14.1.2.2") &&
            output?.other_information?.includes("100%") &&
            FieldInformation,
        },
        {
          title: "Is this a proprietary product?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          default: "No",
          key: "proprietary_product",
          required: true,
          visible: output.show_fssai_category === "No",
        },
        {
          title: "Proprietary Food Name",
          type: FieldType.TEXT,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "proprietary_food_name",
          visible:
            output.proprietary_product === "Yes" &&
            output.show_fssai_category === "No",
          maxLength: 60,
        },
        {
          title: "Show FSSAI Category on Label",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          default: output.proprietary_product === "Yes" ? "No" : "No",
          key: "show_fssai_category",
          required: false,
          visible: output.proprietary_product === "No",
        },
        {
          title: "Standard Food Name",
          type: FieldType.TEXT,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "fssai_category_name",
          visible:
            output.proprietary_product === "No" &&
            output.show_fssai_category === "Yes",
          required: output.show_fssai_category === "Yes",
          maxLength: 60,
        },
      ],
    },
    {
      name: "List of Ingredients and Food Additives",
      description: "",
      analyticsEvent: "LABEL STEP 2 INGREDIENTS AND ADDITIVES VIEW EVENT",
      tags: ["standard", "digital"],
      fields: [
        {
          title: "Is this a single ingredient?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          default: "Yes",
          key: "single_ingredients",
          required: true,
          visible: true,
        },
        {
          title: "Ingredients",
          displayTitle: true,
          type: FieldType.MULTI_INPUT,
          component: ingredients(schemaData, output),
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["item1", "item2"],
          key: "ingredients",
          repeatable: output["single_ingredients"] === "No",
          required: output["single_ingredients"] === "No",
          visible: true,
          draggable: true,
          customStyle: "bg-white border rounded-lg p-5",
          // initialLength: output["single_ingredients"] === "No" ? 2 : 1,
        },
        {
          title:
            "For accurate nutrition calculations, update the recipe in the Nutrition Analysis section when adding additional ingredients.",
          type: FieldType.MESSAGE,
          displayTitle: false,
          reference: "",
          validator: "",
          layout: 12,
          key: "ingredient_message",
          required: false,
          visible:
            output &&
            output.single_ingredients === "No" &&
            output.ingredient_message === true,
          customStyle: "text-[red]",
        },
        {
          title: "Additives",
          displayTitle: true,
          type: FieldType.MULTI_INPUT,
          component: additives(schemaData, output),
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "additives",
          repeatable: true,
          uuid: true,
          visible:
            schemaData.additives?.length > 0
              ? 'output.single_ingredients != "Yes"'
              : false,
          customStyle: "bg-white rounded-lg p-5 first:mb-0 [&>div]:my-5 border",
        },
        {
          title: "No additives are allowed in this food category",
          type: FieldType.MESSAGE,
          displayTitle: false,
          reference: "",
          validator: "",
          layout: 12,
          required: false,
          visible: schemaData.additives?.length === 0,
        },
        {
          title: "Drag to Order Ingredients in Descending Order",
          displayTitle: true,
          type: FieldType.CUSTOM,
          component: DragDropComponent,
          reference: "",
          validator: "",
          country: "IND",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "finalingredients",
          visible: 'output.single_ingredients != "Yes"',
        },
      ],
    },
    {
      name: "Declarations and Allergen",
      description: "",
      analyticsEvent: "LABEL STEP 3 ALLERGENS VIEW EVENT",
      tags: ["standard", "digital"],
      fields: [
        {
          title: "Vegetarian / Non Vegetarian",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: veganDisplay(output),
          key: "vegetarian_and_non_vegetarian",
          required: true,
          visible: true,
        },
        {
          title: "Upload Vegan Certificate (Only PDF upto 2MB are allowed)",
          type: "file",
          allowedFileType: ["application/pdf"],
          maxAllowedSize: 2 * 1024 * 1024,
          multiple: false,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "vegan_certificate",
          visible: output?.vegetarian_and_non_vegetarian === "Vegan",
          help: "The file upload size can be maximum of 2 MB",
        },
        {
          title: "Is your product Organic ?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "organic_product",
          default: "No",
          required: true,
          visible: true,
          help: "The file upload size can be maximum of 2 MB",
        },
        {
          title:
            "Upload Jaivik Bharat Certificate (Only PDF upto 2MB are allowed)",
          type: "file",
          allowedFileType: ["application/pdf"],
          maxAllowedSize: 2 * 1024 * 1024,
          multiple: false,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "organic_certificate",
          visible: output.organic_product === "Yes",
          required: output.organic_product === "Yes",
          help: "The file upload size can be maximum of 2 MB",
        },
        {
          title: "",
          displayTitle: false,
          type: FieldType.CUSTOM,
          component: OrganicCertificateFromComponent,
          reference: "",
          validator: "",
          layout: 12,
          notDefaultHandleChange: true,
          description: "",
          list: [],
          items: [],
          description: `Organic Certification from -`,
          key: "organic_certificate_from",
          visible: output.organic_product === "Yes",
        },
        {
          title: "Is your product AGMARK certified ?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "agmark_certified",
          default: "No",
          required: true,
          visible: true,
          help: "The file upload size can be maximum of 2 MB",
        },
        {
          title: "Upload AGMARK certificate (only PDFs upto 2MB are allowed)",
          type: "file",
          allowedFileType: ["application/pdf"],
          maxAllowedSize: 2 * 1024 * 1024,
          multiple: false,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "agmark_certificate",
          visible: output.agmark_certified === "Yes",
          required: output.agmark_certified === "Yes",
        },
        {
          title: "Is your product Tea Board certified ?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "tea_board_certified",
          default: "No",
          required: true,
          visible: true,
          help: "The file upload size can be maximum of 2 MB",
        },
        {
          title:
            "Upload Tea Board certificate (only PDFs upto 2MB are allowed)",
          type: "file",
          allowedFileType: ["application/pdf"],
          maxAllowedSize: 2 * 1024 * 1024,
          multiple: false,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "tea_board_certificate",
          visible: output.tea_board_certified === "Yes",
          required: output.tea_board_certified === "Yes",
        },
        {
          title: "Is your product India Tea certified ?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "tea_india_certified",
          default: "No",
          required: true,
          visible: true,
          help: "The file upload size can be maximum of 2 MB",
        },
        {
          title:
            "Upload India Tea certificate (only PDFs upto 2MB are allowed)",
          type: "file",
          allowedFileType: ["application/pdf"],
          maxAllowedSize: 2 * 1024 * 1024,
          multiple: false,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "tea_india_certificate",
          visible: output.tea_india_certified === "Yes",
          required: output.tea_india_certified === "Yes",
        },
        {
          title: "India Tea License Number",
          type: FieldType.TEXT,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "tea_india_license_number",
          visible: output.tea_india_certified === "Yes",
          required: output.tea_india_certified === "Yes",
        },
        {
          title: "Is your product BIS certified ?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "bis_certified",
          default: "No",
          required: true,
          visible: true,
          help: "The file upload size can be maximum of 2 MB",
        },
        {
          title: "Upload BIS certificate (only PDFs upto 2MB are allowed)",
          type: "file",
          allowedFileType: ["application/pdf"],
          maxAllowedSize: 2 * 1024 * 1024,
          multiple: false,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "bis_certificate",
          visible: output.bis_certified === "Yes",
          required: output.bis_certified === "Yes",
        },
        {
          title: "BIS License Number",
          type: FieldType.TEXT,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "bis_license_number",
          visible: output.bis_certified === "Yes",
          required: output.bis_certified === "Yes",
        },
        {
          title: "Is your product Fortified ?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "fortified",
          default: "No",
          required: true,
          visible: true,
          help: "The file upload size can be maximum of 2 MB",
        },
        {
          title:
            "Upload Fortification Certificate (Only PDF upto 2MB are allowed)",
          type: "file",
          allowedFileType: ["application/pdf"],
          maxAllowedSize: 2 * 1024 * 1024,
          multiple: false,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "fortification_certificate",
          visible: output.fortified === "Yes",
          required: output.fortified === "Yes",
        },
        {
          title: "Fortified with",
          type: FieldType.MULTI_INPUT,
          component: fortified(output),
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "fortified_with",
          visible: output["fortified"] === "Yes",
          required: output["fortified"] === "Yes",
          repeatable: output["fortified"] === "Yes",
        },
        {
          title: "Is your product irradiated ?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          default: "No",
          key: "irradiated",
          required: true,
          visible: true,
        },
        {
          title: "Name of the Product",
          type: FieldType.TEXT,
          multiple: false,
          reference: "",
          validator: "",
          layout: 4,
          description: "",
          list: [],
          items: [],
          key: "product_name",
          visible: output["irradiated"] === "Yes",
        },
        {
          title: "Purpose of Radiation Processing",
          type: FieldType.TEXT,
          multiple: false,
          reference: "",
          validator: "",
          layout: 4,
          description: "",
          list: [],
          items: [],
          key: "purpose",
          visible: output["irradiated"] === "Yes",
        },
        {
          title: "Operating License No.",
          type: FieldType.TEXT,
          multiple: false,
          reference: "",
          validator: "",
          layout: 4,
          description: "",
          list: [],
          items: [],
          key: "operating_licence_no",
          visible: output["irradiated"] === "Yes",
        },
        {
          title: "Is your product Natural ?",
          type: FieldType.RADIO,
          multiple: false,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "natural_product",
          default: "No",
          visible: true,
          required: true,
          help: "Any single food derived from plant or animal to which nothing has been added and which has been subjected to only minimal processing. Example Pasteurized Milk.",
        },
        {
          title: "Is your product Plant based ?",
          type: FieldType.RADIO,
          multiple: false,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "plant_based_product",
          visible: true,
          default: "No",
          required: true,
          help: "Food Product consisting of ingredients derived from plants and do not contain any animal or animal-derived ingredients such as gelatin",
        },
        {
          title: "Contains Allergens?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "allergens",
          default: "Yes",
          visible: true,
        },
        {
          title: "Contains",
          type: FieldType.CHECKBOX,
          itemsPerRow: 4,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: allergens,
          key: "contains",
          visible: 'output.allergens == "Yes"',
        },
        {
          title: "May Contain",
          type: FieldType.CHECKBOX,
          itemsPerRow: 3,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: allergens,
          key: "may_contains",
          visible: 'output.allergens == "Yes"',
        },
        {
          title: "My product is :",
          type: FieldType.DROPUP,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [
            "Gluten Free",
            "Gluten Free by nature",
            "Processed in a plant where gluten containing products are manufactured",
          ],
          key: "my_product_is",
          visible: true,
        },
      ],
    },
    {
      name: "Nutrition Information",
      description: "",
      tags: ["standard", "digital"],
      fields: [
        {
          title: "Choose NI values from",
          type: FieldType.RADIO,
          multiple: false,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [
            "My Recipes",
            "Lab Report",
            "I want to fill in my own NI values",
          ],
          itemsPerRow: 1,
          key: "ni_info_type",
          default: "I want to fill in my own NI values",
          visible: true,
          required: output["single_ingredients"] === "No",
          help: "The file upload size can be maximum of 2 MB",
        },
        {
          title: "",
          type: FieldType.BLANK,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "",
          visible:
            output["ni_info_type"] === "I want to fill in my own NI values",
        },
        {
          title:
            "Upload Lab Report of Nutrition Analysis (Only PDF upto 2MB are allowed)",
          type: FieldType.FILE,
          allowedFileType: ["application/pdf"],
          maxAllowedSize: 2 * 1024 * 1024,
          multiple: false,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "lab_report",
          visible: output["ni_info_type"] === "Lab Report",
          required: output["ni_info_type"] === "Lab Report",
        },
        {
          title: "Search from My Recipes",
          type: FieldType.SEARCH,
          reference: "",
          validator: "",
          description: "",
          layout: 6,
          list: [],
          items: [],
          key: "RECIPE_SEARCH",
          defaultValue: "",
          searchApi: getRecipesV3,
          searchParams: {
            limit: 5,
            business_id: schemaData.businessId,
            type: RECIPE_TYPES.NEW_RECIPE,
          },
          mapper: (res) => [
            {
              indexName: "Recipes",
              data: res?.data?.map((recipe) => ({ ...recipe, id: recipe._id })),
            },
          ],
          selectedRender: SelectedRecipeWidget,
          required: false,
          visible: output["ni_info_type"] === "My Recipes",
        },
        {
          title: "Select Target Audience",
          type: FieldType.DROPDOWN,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: Object.keys(targetAudience),
          key: "target_audience",
          visible: true,
          required: output["single_ingredients"] === "No" || true,
        },
        {
          title: "Age Group",
          type: FieldType.DROPDOWN,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: targetAudience[output["target_audience"]],
          key: "age_group",
          visible: true,
          required: output["single_ingredients"] === "No" || true,
        },
        {
          title: "Serving Size",
          type: FieldType.NUMBER,
          reference: "",
          validator: "",
          layout: 3,
          description: "",
          list: [],
          items: [],
          key: "serving_size",
          visible: true,
          required: output["single_ingredients"] === "No" || true,
        },
        {
          title: "Serving Size Unit",
          type: FieldType.DROPDOWN,
          reference: "",
          validator: "",
          layout: 3,
          description: "",
          list: [],
          items: ["g", "L", "ml", "kg"],
          key: "serving_size_unit",
          visible: true,
          default: false,
          required: true,
        },
        {
          title: "Net Quantity",
          type: FieldType.NUMBER,
          reference: "",
          validator: "",
          layout: 3,
          description: "",
          list: [],
          items: [],
          key: "net_quantity",
          visible: true,
          required: output["single_ingredients"] === "No" || true,
        },
        {
          title: "Net Quantity Unit",
          type: FieldType.DROPDOWN,
          reference: "",
          validator: "",
          layout: 3,
          description: "",
          list: [],
          items: ["g", "L", "ml", "kg"],
          key: "net_quantity_unit",
          visible: true,
          default: false,
          required: output["single_ingredients"] === "No" || true,
        },
        {
          title: "Mandatory Nutrient Information (per 100 g/ml)",
          displayTitle: true,
          type: FieldType.MULTI_INPUT,
          component: mandatoryNutrients(output),
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "mandatory_nutrient",
          visible: true,
          customStyle: "bg-white border rounded-lg p-5",
          help: `<p class="text-sm">Declared nutrients to comply to a tolerance of maximum ± 20 percent 
        of the value for that nutrient at any point in the time within declared shelf life of the product.</p>`,
          required: output["single_ingredients"] === "No",
          editable: output["ni_info_type"] !== "My Recipes",
        },
        {
          title: "Other Nutrient Information (per 100 g/ml)",
          displayTitle: true,
          type: FieldType.MULTI_INPUT,
          component: nonMandatoryNutrients(output, schemaData),
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "non_mandatory_nutrient",
          customStyle: "bg-white border rounded-lg p-5",
          visible: true,
          repeatable: true,
          // editable:
          //   output["ni_info_type"] !== "My Recipes" &&
          //   output["ni_info_type"] !== "RECIPE_SEARCH",
          // disabled:
          //   output["ni_info_type"] !== "My Recipes" ||
          //   output["ni_info_type"] !== "RECIPE_SEARCH",
          // disable_text: "Default values from recipe",
        },
        {
          title:
            "Does Your Product Use Industrial Transfat Free Fat/Oil And Contains Less Than 0.2g Per 100g Or 100ml?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "industrial_trans_fat_free",
          visible: true,
        },
      ],
      postStepCallback: niInfoValidator,
    },
    {
      name: "Manufacturer Details",
      description: "",
      tags: ["standard", "digital"],
      fields: [
        {
          title: "Manufactured by",
          type: FieldType.DROPDOWN,
          multiple: true,
          validator: "",
          layout: 6,
          description: "",
          list: schemaData.addresses?.map((v) => ({
            key: v.addressId,
            value: `${v.name}, ${v.addressLine1}`,
          })),
          items: [],
          key: "manufacturer_by",
          visible: true,
          help: "",
          required: true,
        },
        {
          title: " ",
          type: FieldType.TEXT_BUTTON,
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "Create new address",
          visible: true,
          help: "",
          link: `${BASE_ROUTES.PPF}/business-profile`,
        },
        {
          title: "Packed by",
          type: FieldType.CHECKBOX,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          items: ["Same as manufactured by"],
          key: "same_as_manufactured_by",
          visible: true,
          required: false,
          disable:
            !output?.["manufacturer_by"] ||
            output?.["manufacturer_by"]?.length === 0,
        },
        {
          title: "Packed by",
          displayTitle: false,
          type: FieldType.DROPDOWN,
          multiple: true,
          validator: "",
          layout: 6,
          description: "",
          list: schemaData.addresses?.map((v) => ({
            key: v.addressId,
            value: `${v.name}, ${v.addressLine1}`,
          })),
          items: [],
          key: "packed_by",
          visible: true,
          help: "",

          disable: output?.["same_as_manufactured_by"]?.length > 0,
        },
        {
          title: "",
          type: FieldType.TEXT_BUTTON,
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "Create new address",
          visible: true,
          help: "",
          link: `${BASE_ROUTES.PPF}/business-profile`,
        },
        {
          title: "Marketed by",
          type: FieldType.DROPDOWN,
          multiple: true,
          validator: "",
          layout: 6,
          description: "",
          list: schemaData.addresses?.map((v) => ({
            key: v.addressId,
            value: `${v.name}, ${v.addressLine1}`,
          })),
          items: [],
          key: "marketed_by",
          required: true,
          visible: true,
          help: "",
        },
        {
          title: " ",
          type: FieldType.TEXT_BUTTON,
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "Create new address",
          visible: true,
          help: "",
          link: `${BASE_ROUTES.PPF}/business-profile`,
        },
        {
          title: "Brand Owner",
          type: FieldType.DROPDOWN,
          multiple: false,
          validator: "",
          layout: 6,
          description: "",
          list: schemaData.addresses?.map((v) => ({
            key: v.addressId,
            value: `${v.name}, ${v.addressLine1}`,
          })),
          items: [],
          key: "brand_owner",
          visible: true,
          required: false,
          help: "",
        },
        {
          title: " ",
          type: FieldType.TEXT_BUTTON,
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "Create new address",
          visible: true,
          help: "",
          link: `${BASE_ROUTES.PPF}/business-profile`,
        },
        {
          title: "Is this product imported ?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "imported",
          required: false,
          visible: true,
        },
        {
          title: "",
          type: FieldType.BLANK,
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "",
          visible: true,
        },
        {
          title: "Country of Origin ",
          type: FieldType.TEXT,
          reference: "",
          validator: "",
          layout: 4,
          description: "",
          list: [],
          items: [],
          key: "country_of_origin",
          visible: 'output.imported == "Yes"',
        },
        {
          title: "Imported by",
          type: FieldType.DROPDOWN,
          multiple: false,
          reference: "address",
          validator: "",
          layout: 4,
          description: "",
          list: schemaData.addresses?.map((v) => ({
            key: v.addressId,
            value:
              v.addressLine1 +
              "," +
              v.addressLine2 +
              "," +
              v.city +
              "," +
              v.postalCode +
              "," +
              v.state +
              "," +
              v.country,
          })),
          items: [],
          key: "imported_by",
          visible: 'output.imported == "Yes"',
          help: "",
        },
        {
          title: " ",
          type: FieldType.TEXT_BUTTON,
          validator: "",
          layout: 4,
          description: "",
          list: [],
          items: [],
          key: "Create new address",
          help: "",
          link: `${BASE_ROUTES.PPF}/business-profile`,
          visible: 'output.imported == "Yes"',
        },
        {
          title: "Consumer Care Details",
          displayTitle: true,
          type: FieldType.MULTI_INPUT,
          component: consumerCare(schemaData, output),
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "consumer_care",
          customStyle: "bg-white border rounded-lg p-5",
          repeatable: false,
          required: false,
          visible: true,
        },
        {
          text: "Instructions of Use and Storage Instructions",
          styles: "text-xl font-bold",
          layout: 12,
          type: FieldType.DISPLAY_TEXT,
          underline: true,
        },
        {
          title: "Use and Storage Instructions",
          displayTitle: false,
          type: FieldType.MULTI_INPUT,
          component: useStorageInstructions(schemaData, output),
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "use_storage_instructions",
          repeatable: false,
          required: false,
          visible: true,
        },
        
        {
          title: "Is this product Made in India ?",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "made_in_india",
          required: false,
          visible: true,
        },
      ],
    },
    {
      name: "Mandatory Declarations",
      tags: ["standard", "digital"],
      fields: [
        {
          title: "",
          type: FieldType.INFO,
          description: `Does your product contain any of the following`,
          layout: 12,
        },
        {
          title: "Plant Stanol Esters",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "plant_stanol_esters",
          visible: true,
          default: "No",
          required: true,
        },
        {
          title: "kcal per 100g or 100ml",
          type:
            output.plant_stanol_esters === "Yes"
              ? FieldType.NUMBER
              : FieldType.BLANK,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "plant_stanol_esters_qty",
          visible: true,
          required: output.plant_stanol_esters === "Yes",
        },
        {
          title: "Isomaltulose",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "isomaltulose",
          visible: true,
          default: "No",
          required: true,
        },
        {
          title: "kcal per 100g or 100ml",
          type:
            output.isomaltulose === "Yes" ? FieldType.NUMBER : FieldType.BLANK,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "isomaltulose_qty",
          visible: true,
          required: output.isomaltulose === "Yes",
        },
        {
          title: "Annatto colour",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "annattto_color",
          default: "No",
          visible: true,
          required: true,
        },
        {
          title: "10% or more polyols",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          default: "No",
          key: "10%_or_more_polyols",
          visible: true,
          required: true,
        },
        {
          title: "10% or more polydextrose",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          default: "No",
          key: "10%_or_more_polydextrose",
          visible: true,
          required: true,
        },
        {
          title: "Added caffeine",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          default: "No",
          key: "added_caffeine",
          visible: true,
          required: true,
        },
        {
          title: "10% Or More Sorbitol And Sorbitol Syrup",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          default: "No",
          key: "10%_or_more_sorbitol_and_sorbitol_syrup",
          visible: true,
          required: true,
        },
        {
          title: "Trehalose",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "trehalose",
          visible: true,
          default: "No",
          required: true,
        },
        {
          title: "Monosodium Glutamate",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "monosodium_glutamate",
          visible: true,
          default: "No",
          required: true,
        },
        {
          title: "Added Dietary Fibre (Dextrin-soluble fibre)",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Yes", "No"],
          key: "added_dietary_fibre",
          visible: true,
          default: "No",
          required: true,
        },
        ...generateSweetners(),
        {
          title: "Mixture of Sweeteners",
          displayTitle: true,
          type: FieldType.DROPDOWN,
          multiple: true,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [
            "Aspartame (Methyl Ester)",
            "Acesulfame Potassium",
            "Aspartame-Acesulfame Salt",
            "Saccharins",
          ],
          key: "mixture_of_sweeteners",
          customStyle:
            "[&>div>span]:font-normal [&>div>span]:ml-4 [&>div>span]:text-base [&>div>button]:ml-4",
          visible: true,
        },
        {
          title: "Mandatory Declarations by category",
          type: FieldType.CHECKBOX,
          reference: "",
          validator: "",
          itemsPerRow: 4,
          layout: 12,
          description: "",
          list: [],
          items: getMDCheckboxes(output),
          key: "mandatory_declaration",
          visible: getMDCheckboxes(output)?.length > 0,
          default: [],
        },
        {
          title: "Reason for using Common Salt",
          displayTitle: true,
          type: FieldType.DROPDOWN,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [
            "For Iodisation",
            "For Iron fortification",
            "For Animal use",
            "For Preservation and not meant for direct consumption",
            "For Infant Food products",
            "For medicine",
            "For Industrial Use",
          ],
          key: "common_salt_for",
          customStyle: "[&>div>span]:font-normal",
          visible: output.mandatory_declaration?.includes("Common Salt"),
        },
        {
          title: "Select Wax Type coated on Fresh Fruit",
          displayTitle: true,
          type: FieldType.DROPDOWN,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [
            "Bees wax (white)",
            "Bees wax (yellow)",
            "Carnauba wax",
            "Shellac wax",
          ],
          key: "wax",
          customStyle: "[&>div>span]:font-normal",
          visible:
            output?.category?.category === "4.1.1.2" &&
            output.mandatory_declaration?.includes(
              "Fresh fruits coated with wax",
            ),
        },
        {
          title: "Name of Oil",
          displayTitle: true,
          type: FieldType.TEXT,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "name_of_oil",
          customStyle: "[&>div>span]:font-normal",
          visible:
            output.food_name?.toLowerCase() === "refined oil" &&
            output.flavour?.toLowerCase() !== "vanaspati",
        },
        {
          title: "Name of the Refined Oil",
          displayTitle: true,
          type: FieldType.TEXT,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "refined_oil_name",
          customStyle: "[&>div>span]:font-normal",
          visible:
            output.mandatory_declaration?.includes("Refined Oil") ||
            (output.food_name === "Refined Oil" &&
              !output.flavour === "Coconut oil"),
        },
        {
          title: "Blended Oil",
          type: FieldType.MULTI_INPUT,
          displayTitle: true,
          component: blendedOil,
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "blended_oil",
          repeatable: true,
          customStyle: "bg-white border rounded-lg p-5",
          visible:
            'output.mandatory_declaration?.includes("This oil is a Blended Oil")',
        },
        {
          title: "% of coffee in the mixture",
          displayTitle: true,
          type: FieldType.TEXT,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "percent_by_coffee",
          customStyle: "[&>div>span]:font-normal",
          visible:
            'output.mandatory_declaration?.includes("This product is a mixture of coffee and chicory") || output.mandatory_declaration?.includes("This product is a mixture of instant coffee and chicory")',
        },
        {
          title: "% of chicory in the mixture",
          displayTitle: true,
          type: FieldType.TEXT,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          key: "percent_by_chicory",
          customStyle: "[&>div>span]:font-normal",
          visible:
            'output.mandatory_declaration?.includes("This product is a mixture of coffee and chicory") || output.mandatory_declaration?.includes("This product is a mixture of instant coffee and chicory")',
        },
        {
          title: "Common Salt For",
          displayTitle: true,
          type: FieldType.DROPDOWN,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: ["Iodisation", "Iron fortification"],
          key: "common_salt_for",
          customStyle: "[&>div]:pl-6 [&>div>span]:font-normal",
          visible:
            output.food_name === "Edible Common Salt" &&
            output?.category?.category === "12.1.1",
        },
      ],
    },
    {
      name: "Plastic Waste Management",
      description: "",
      analyticsEvent: "LABEL STEP 7 FONTS AND LOGO EVENT",
      tags: ["standard", "digital"],
      fields: [
        {
          title: "Type of Packaging Material",
          items: [
            "Multi-Layered Plastic",
            "Recycled Plastic",
            "Not Applicable",
          ],
          type: FieldType.CHECKBOX,
          multiple: true,
          key: "package_material_type",
          required: true,
          visible: true,
        },
        {
          title: "Enter Name & Registration Number of Brand Owner",
          type: FieldType.TEXT,
          key: "brand_registration_number",
          required: false,
          maxLength: 100,
          visible:
            output["package_material_type"]?.includes(
              "Multi-Layered Plastic",
            ) ?? false,
        },
        {
          title: "Select the Appropiate Recycling Icon If Applicable",
          type: FieldType.RADIO,
          key: "recycling_icon",
          list: [
            "/plastic_icons/pet.png",
            "/plastic_icons/hdpe.png",
            "/plastic_icons/pvc.png",
            "/plastic_icons/pe-ld.png",
            "/plastic_icons/pp.png",
            "/plastic_icons/ps.png",
            "/plastic_icons/o.png",
          ].map((v) => ({
            key: v,
            value: <PlasticIcon image={v} />,
          })),
          hideRadio: true,
          required:
            output["package_material_type"]?.includes("Recycled Plastic"),
          visible:
            output["package_material_type"]?.includes("Recycled Plastic") ??
            false,
        },
      ],
    },
    {
      name: "Fonts And Logo Dimensions (Legal Metrology & FSSAI)",
      description: "",
      analyticsEvent: "LABEL STEP 8 FONTS AND LOGO EVENT",
      tags: ["standard", "digital"],
      fields: [
        {
          title: "Indicate Pack Shape",
          list: [
            {
              key: "rectangle",
              value: "Rectangle/Square",
            },
            {
              key: "cylinderical",
              value: "Cylinderical/Round/Oval",
            },
          ],
          itemsPerRow: 2,
          type: FieldType.RADIO,
          key: "pack_shape",
          required: true,
          visible: true,
        },
        {
          title: "Please Select Unit",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["cm", "mm"],
          key: "unit",
          default: "cm",
          required: true,
          visible: true,
        },
        {
          title: "Mention Pack Dimensions",
          displayTitle: true,
          layout: 6,
          type: FieldType.MULTI_INPUT,
          component: packDimension(output),
          description: "",
          key: "pack_dimension",
          visible: true,
          required: true,
          customStyle: "[&>div]:pl-6 [&>div>span]:font-normal",
        },
        {
          title: "Mention Label Dimensions",
          displayTitle: true,
          layout: 6,
          type: FieldType.MULTI_INPUT,
          component: labelDimension(output),
          description: "",
          key: "label_dimension",
          visible: true,
          required: true,
          customStyle: "[&>div]:pl-6 [&>div>span]:font-normal",
        },
        {
          text: "SKU Details",
          styles: "text-xl font-bold",
          layout: 12,
          type: FieldType.DISPLAY_TEXT,
          underline: true,
        },
        {
          title: "MRP",
          displayTitle: true,
          layout: 6,
          type: FieldType.MULTI_INPUT,
          component: mrp(output),
          description: "",
          key: "mrp_title",
          visible: true,
          required: false,
          customStyle: "[&>div]:pl-6 [&>div>span]:font-normal",
        },
        {
          title: "USP",
          displayTitle: true,
          layout: 6,
          type: FieldType.MULTI_INPUT,
          component: usp(output),
          description: "",
          key: "usp_title",
          visible: true,
          required: false,
          customStyle: "[&>div]:pl-6 [&>div>span]:font-normal",
          // help: `<p class="text-sm">The autocalculated value is determined by dividing the MRP (Maximum Retail Price) by the Net Quantity.
          // For autocalculation Please make USP (Cost per Unit-Rs.) empty or you can add your own value.</p>`,
        },
        {
          title: "Date of Packaging/Manufacture",
          displayTitle: true,
          layout: 6,
          type: FieldType.MULTI_INPUT,
          component: dateOfPkgMfg(output),
          description: "",
          key: "date_of_pkg_mfg_title",
          visible: true,
          required: false,
          customStyle: "[&>div]:pl-6 [&>div>span]:font-normal",
        },
        {
          title: "Use By/Expiry Date",
          displayTitle: true,
          layout: 6,
          type: FieldType.MULTI_INPUT,
          component: dateOfUsebyExp(output),
          description: "",
          key: "useBy_expiry_title",
          visible: true,
          required: false,
          customStyle: "[&>div]:pl-6 [&>div>span]:font-normal",
        },
        {
          title: "Batch Number",
          displayTitle: true,
          layout: 6,
          type: FieldType.MULTI_INPUT,
          component: batch_no(output),
          description: "",
          key: "batch_number_title",
          visible: true,
          required: false,
          customStyle: "[&>div]:pl-6 [&>div>span]:font-normal",
        },
        {
          text: "Bar Code",
          styles: "text-xl font-bold",
          layout: 12,
          type: FieldType.DISPLAY_TEXT,
          underline: true,
        },
        {
          title: "Add Barcode Image",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: ["Yes", "No"],
          default: "No",
          key: "add_barcode",
          required: true,
          visible: true,
        },
        {
          title: "Upload Bar Code (Only Images are allowed)",
          type: FieldType.FILE,
          allowedFileType: [
            "image/jpeg",
            "image/png",
            "image/gif",
            "image/svg+xml",
          ],
          maxAllowedSize: 2 * 1024 * 1024,
          multiple: false,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: [],
          key: "bar_code",
          visible: output.add_barcode === "Yes",
          required: output.add_barcode === "Yes",
        },
        {
          title: "Add Barcode Number",
          type: FieldType.RADIO,
          reference: "",
          validator: "",
          layout: 12,
          description: "",
          list: [],
          items: output.add_barcode === "Yes" ? ["No"] : ["Yes", "No"],
          default: "No",
          key: "add_barcode_number",
          required: true,
          visible: true,
          help: "The barcode number should be up to 13 digits only.",
        },
        {
          title: "Add Barcode Number",
          type: FieldType.NUMBER,
          reference: "",
          validator: "",
          layout: 6,
          description: "",
          list: [],
          items: [],
          default: "",
          key: "barcode_number",
          required: true,
          visible:
            output.add_barcode === "No" && output.add_barcode_number === "Yes",
        },
        {
          title: "LM & FSSAI Guidelines",
          styles: "text-xl font-bold",
          type: FieldType.INFO,
          description: `<p>Batch identification and date marking elements to be filled in by the manufacturer post packaging stage, in the formats mentioned below</p>
        <p><b>1.  Net Quantity</b> - Mention quantity by weight (g, kg, ml, l), measure or number of commodity in that package, excluding the packaging or wrapper</p>
        <p><b>2. MRP (inclusive of all taxes)</b></p>
<p><b>3. Unit Sale Price (USP)</b> - USP statement needs to be declared in close proximity to the MRP and should based on the unit used for declaring Net Quantity eg- Rs.__ per g or Rs.__ per kg or Rs.__ per litre, etc.</p>
<p><b>4. Batch no./ Lot no.</b></p>
<p><b>5. Date Markings - Elements Mentioned Below Should Have The Following Format</b><br />
 - Products with short shelf life upto 3 months- DD/MM/YY <br />
- Products with shelf life longer than 3 months- MMM/YY or DD/MM/YY </p>
<p><b>a. Date of manufacturing</b> - Food with shelf-life of not more than seven days, the 'date of manufacture' may not be required to be mentioned on the label </p>
<p><b>b. Date of packing</b></p>
<p><b>c. Expiry date / Use by date</b></p>
<p><b>d. Best before</b> (optional/ additional info)</p>`,
          layout: 12,
        },
      ],
    },
  ]
}
export default LabelMakerSchema
