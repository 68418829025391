import React, { useEffect, useState, useRef } from "react"
import { Popover } from "@headlessui/react"
import { DropdownIcon } from "../assets/icons/Icons"
import { mapListToFormOptions } from "./helper"
import { genUniqueArray } from "../Utils/helpers"
import PlusCircle from "../common/Icons/PlusCircle"

export default function QkAutoSuggest(props) {
  const { values, data, handleChange } = props

  const disabled = data.disabled
  const [query, setQuery] = useState(values[data.key] ?? "")
  const [active, setActive] = useState(values[data.key] ? true : false)
  const [showSuggestion, setShowSuggestion] = useState(false)
  const ref = useRef(null)

  let list = mapListToFormOptions(data) ?? []
  let filteredList = []

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setShowSuggestion(false)
      setQuery(values[data.key] ?? "")
    }
  }

  useEffect(() => {
    setQuery(values[data.key] ?? "")
    if (values[data.key]) setActive(true)
    else setActive(false)
  }, [values, data.key])

  const handleInputChange = (e) => {
    setQuery(e.target.value)
    setShowSuggestion(true)
  }

  const handleInputBlur = () => {
    const trimmedQuery = query.trim()
    setQuery(trimmedQuery)
    if (trimmedQuery) {
      handleChange(trimmedQuery)
    } else {
      handleChange("")
    }
  }

  const handleSelectChange = (value) => {
    const trimmedValue = value.trim()
    if (trimmedValue) {
      setQuery(trimmedValue)
      handleChange(trimmedValue)
    } else {
      setQuery("")
      handleChange("")
    }
    setShowSuggestion(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref])

  filteredList =
    query === ""
      ? list
      : list.filter((v) => {
          return v.value?.toLowerCase().includes(query?.toLowerCase())
        })

  if (data.sort) {
    filteredList.sort((a, b) => {
      if (data.sort === -1) return b.value.localeCompare(a.value)
      return a.value.localeCompare(b.value)
    })
  }

  const placeholder = `${data.title} ${data.required ? "*" : ""}`

  if (!values || data.visible === false) return <></>
  const queryValues = filteredList.map((item) => item.value.toLowerCase())

  return (
    <Popover>
      <div
        className={`relative ${
          props.data.disabled ? "opacity-75 cursor-not-allowed" : ""
        }`}
      >
        <div
          className={`w-full flex items-center rounded-lg border border-bordergray bg-white px-1 shadow-sm focus-within:border-none focus-within:outline-none focus-within:ring-1 focus-within:ring-black sm:text-sm ${
            disabled ? "pointer-events-none opacity-75" : ""
          }`}
        >
          {!data.hideTitleOnActive && active && (
            <p className="absolute mt-[-45px] text-textgray bg-[white] ml-[10px] px-[5px] py-0 scale-75 z-10">
              {placeholder}
            </p>
          )}
          <input
            maxLength={data.maxLength ?? 25}
            type="text"
            className="border-none focus:outline-none placeholder:text-textgraylight focus:ring-0 w-full leading-8"
            value={query ?? ""}
            placeholder={placeholder}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            disabled={disabled}
          />
          {data.dropdownIcon ? (
            <div
              className="text-textgraylight float-right mr-3 mt-3 w-6 h-6 cursor-pointer"
              onClick={() => setShowSuggestion(true)}
            >
              <DropdownIcon className="" />
            </div>
          ) : (
            ""
          )}
        </div>
        {(filteredList?.length > 0 || query !== "") && (
          <Popover.Panel
            static={showSuggestion}
            ref={ref}
            className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {({ close }) => (
              <>
                {query !== "" && !data?.cantCreateQuery && (
                  <button
                    className="relative cursor-pointer hover:bg-gray-100 select-none py-2 pl-3 pr-9 w-full text-left"
                    onClick={() => handleSelectChange(query)}
                  >
                    <div className="flex">
                      <PlusCircle className="h-4 w-4 mt-[1px] mr-[1px] text-primary" />
                      <span
                        style={{ color: "rgb(249, 115, 22)" }}
                        className="font-bold mr-1"
                      >
                        Create
                      </span>{" "}
                      {queryValues.includes(query.toLowerCase()) ? "" : query}
                    </div>
                  </button>
                )}
                {filteredList.length === 0 && data?.cantCreateQuery && (
                  <div className="italic inline-flex w-full justify-center py-2 text-gray-500">
                    No Options Found
                  </div>
                )}
                {genUniqueArray(filteredList, "key").map((v) => (
                  <button
                    key={v.key}
                    className="relative cursor-pointer hover:bg-gray-100 select-none py-2 pl-3 pr-9 w-full text-left"
                    onClick={() => handleSelectChange(v.key ?? v.value)}
                  >
                    {v.value}
                  </button>
                ))}
              </>
            )}
          </Popover.Panel>
        )}
        {data && data?.noteText && (
          <div className="italic text-[orange] ml-1">{data?.noteText}</div>
        )}
        {data?.infoComponent && <data.infoComponent />}
      </div>
    </Popover>
  )
}
