export const LOGIN_SUCCESS = "LOGIN SUCCESS"
export const SIGNUP_SUCCESS = "SIGNUP SUCCESS"
export const CREATE_LABEL_BUTTON_CLICKED = "CREATE LABEL BUTTON CLICKED"
export const CREATE_RECIPE_BUTTON_CLICKED = "CREATE RECIPE BUTTON CLICKED"
export const CREATE_LABEL_SUCCESS = "CREATE LABEL SUCCESS"
export const CREATE_RECIPE_SUCCESS = "CREATE RECIPE SUCCESS"
export const CREATE_INGREDIENT_SUCCESS = "CREATE INGREDIENT SUCCESS"
export const CREATE_BASE_RECIPE_SUCCESS = "CREATE BASE RECIPE SUCCESS"
export const VIEW_DETAILS_CLICKED = "VIEW DETAILS CLICKED"
export const BASE_RECIPE_SORT_APPLY_SUCCESS = "BASE RECIPE SORT APPLY SUCCESS"
export const LOAD_MORE_RECIPES_CLICKED = "LOAD MORE RECIPES CLICKED"
export const LOAD_MORE_INGREDIENTS_CLICKED = "LOAD MORE INGREDIENTS CLICKED"
export const LOAD_MORE_BASE_RECIPES_CLICKED = "LOAD MORE BASE RECIPES CLICKED"
export const EDIT_INGREDIENT_CLICKED = "EDIT INGREDIENT CLICKED"
export const EDIT_LABEL_CLICKED = "EDIT LABEL CLICKED"
export const DUPLICATE_LABEL_CLICKED = "DUPLICATE LABEL CLICKED"
export const DELETE_INGREDIENT_CLICKED = "DELETE INGREDIENT CLICKED"
export const CREATE_INGREDIENT_CLICKED = "CREATE INGREDIENT CLICKED"
export const BULK_UPLOAD_INGREDIENT_CLICKED = "BULK UPLOAD INGREDIENT CLICKED"
export const CREATE_BASE_RECIPE_CLICKED = "CREATE BASE RECIPE CLICKED"
export const RECIPE_FILTER_CLICKED = "RECIPE FILTER CLICKED"
export const RECIPE_FILTER_APPLY_SUCCESS = "RECIPE FILTER APPLY SUCCESS"
export const RECIPE_FILTER_CLEAR_SUCCESS = "RECIPE FILTER CLEAR SUCCESS"
export const DASHBOARD_SIDEBAR_ICON_CLICKED = "DASHBOARD SIDEBAR ICON CLICKED"
export const AILABELREVIEW_SIDEBAR_ICON_CLICKED =
  "AILABELREVIEW SIDEBAR ICON CLICKED"
export const RECIPE_SIDEBAR_ICON_CLICKED = "RECIPE SIDEBAR ICON CLICKED"
export const PRINT_LABEL_SIDEBAR_ICON_CLICKED =
  "PRINT LABEL SIDEBAR ICON CLICKED"
export const INGREDIENT_SIDEBAR_ICON_CLICKED = "INGREDIENT SIDEBAR ICON CLICKED"
export const BASE_RECIPE_SIDEBAR_ICON_CLICKED =
  "BASE RECIPE SIDEBAR ICON CLICKED"
export const RESOURCES_SIDEBAR_ICON_CLICKED = "RESOURCES SIDEBAR ICON CLICKED"
export const CONTACT_US_SIDEBAR_ICON_CLICKED = "CONTACT US SIDEBAR ICON CLICKED"
export const PROFILE_SIDEBAR_ICON_CLICKED = "PROFILE SIDEBAR ICON CLICKED"
export const BUSINESS_PROFILE_SIDEBAR_ICON_CLICKED =
  "BUSINESS PROFILE SIDEBAR ICON CLICKED"
export const DOWNLOAD_LABEL_SUCCESS = "DOWNLOAD LABEL SUCCESS"
export const EDIT_RECIPE_BUTTON_CLICKED = "EDIT RECIPE BUTTON CLICKED"
export const VERSIONS_RECIPE_BUTTON_CLICKED =
  "RECIPE VERSION HISTORY BUTTON CLICKED"
export const SAVE_MERCHANT_RECIPE_SUCCESS = "SAVE MERCHANT RECIPE SUCCESS"
export const MERCHANT_INGREDIENT_SELECTED = "MERCHANT INGREDIENT SELECTED"
export const MERCHANT_REMOVE_INGREDIENT = "MERCHANT REMOVE INGREDIENT"
export const DROPDOWN_SELECTED = "DROPDOWN SELECTED"
export const MERCHANT_INGREDIENT_FETCH = "MERCHANT INGREDIENT FETCH"
export const MANDATORY_DECLARATION_SELECTED = "MANDATORY DECLARATION SELECTED"
export const RECIPE_INGREDIENT_FETCH = "RECIPE INGREDIENT FETCH"
export const MERCHANT_FOOTER_REDIRECT_CLICKED =
  "MERCHANT FOOTER REDIRECT CLICKED"
export const EDIT_RECIPE_SUCCESS = "EDIT RECIPE SUCCESS"
export const DOWNLOAD_RECIPE_BUTTON_CLICKED = "DOWNLOAD RECIPE BUTTON CLICKED"
export const DOWNLOAD_RECIPE_SUCCESS = "DOWNLOAD RECIPE SUCCESS"
export const PAGE_VIEW_EVENT = "PAGE VIEW EVENT"
export const SEARCH_BAR_EVENT = "SEARCH BAR EVENT"
export const EDIT_PROFILE_BUTTON_CLICKED = "EDIT PROFILE BUTTON CLICKED"
export const UPDATE_PROFILE_BUTTON_CLICKED = "UPDATE PROFILE BUTTON CLICKED"
export const UPDATE_PROFILE_SUCCESS = "UPDATE PROFILE SUCCESS"
export const PROFILE_EDIT_COMPANY_BUTTON_CLICKED =
  "PROFILE EDIT COMPANY EVENT CLICKED"
export const PROFILE_UPDATE_COMPANY_SUCCESS = "PROFILE UPDATE COMPANY SUCCESS"
export const CREATE_NEW_RECIPE_CLICKED = "CREATE NEW RECIPE CLICKED"
export const CHOOSE_RECIPE_FROM_STANDARD_RECIPE_CLICKED =
  "CHOOSE RECIPE FROM STANDARD RECIPE CLICKED"
export const CREATE_BASE_RECIPE_FROM_RECIPE_PAGE_CLICKED =
  "CREATE BASE RECIPE FROM RECIPE PAGE CLICKED"
export const CREATE_DRAFT_RECIPE_CLICKED = "CREATE DRAFT RECIPE CLICKED"
export const DOWNLOAD_LABEL_FROM_RECIPE_PAGE_CLICKED =
  "DOWNLOAD LABEL FROM RECIPE PAGE CLICKED"

export const CREATE_COMBO_MEALS_FROM_RECIPE_PAGE_CLICKED =
  "CREATE COMBO MEALS FROM RECIPE PAGE CLICKED"
export const NavbarAnalyticsEventMap = {
  Home: "NAVBAR HOME CLICKED",
  CleanLabelReport: "NAVBAR CLEAN LABEL REPORT CLICKED",
  HowItWorks: "NAVBAR HOW IT WORKS CLICKED",
  AboutUs: "NAVBAR ABOUT US CLICKED",
  Pricing: "NAVBAR PRICING CLICKED",
  Blogs: "NAVBAR BLOGS CLICKED",
  FAQs: "NAVBAR FAQS CLICKED",
  Logout: "NAVBAR LOGOUT CLICKED",
}
export const LableStepsAnalyticsMap = {
  name_of_food: "LABEL STEP 1 NAME OF FOOD VIEW EVENT",
  ingredients_and_additives:
    "LABEL STEP 2 INGREDIENTS AND ADDITIVES VIEW EVENT",
  allergens: "LABEL STEP 3 ALLERGENS VIEW EVENT",
  declarations: "LABEL STEP 4 DECLARATIONS VIEW EVENT",
  nutrition_information: "LABEL STEP 5 NUTRITION INFORMATION VIEW EVENT",
  manufacturer_details: "LABEL STEP 6 MANUFACTURER DETAILS VIEW EVENT",
  batch_identification: "LABEL STEP 7 BATCH IDENTIFICATION VIEW EVENT",
  usage_instructions: "LABEL STEP 8 USAGE INSTRUCTIONS VIEW EVENT",
  mandatory_declarations: "LABEL STEP 9 MANDATORY DECLARATIONS VIEW EVENT",
  EDIT_LABEL_SUCCESS: "EDIT LABEL SUCCESS",
}
export const UserDashboardAnalyticsMap = {
  date_filter_from: "FROM DATE FILTER CLICKED",
  date_filter_to: "TO DATE FILTER CLICKED",
  date_filter_clear: "DATE FILTER CLEAR CLICKED",
  view_recipe_event: "USER DASHBOARD VIEW RECIPE EVENT",
  view_label_event: "USER DASHBOARD VIEW LABEL EVENT",
  view_all_event: "USER DASHBOARD VIEW ALL CLICKED",
  food_category_filter_event: "USER DASHBOARD FOOD CATEGORY FILTER CLICKED",
}
export const UserReviewAnalyticsMap = {
  model_open_recipe_event: "USER REVIEW MODEL OPEN RECIPE EVENT",
  view_recipe_event: "USER REVIEW VIEW RECIPE EVENT",
  recipe_approve_event: "USER RECIPE APPROVE EVENT",
  recipe_disapprove_event: "USER RECIPE DISAPPROVE EVENT",
  search_bar_event: "USER REVIEW SEARCH BAR EVENT",
  status_filter: "USER REVIEW STATUS FILTER CLICKED",
  brand_filter: "USER REVIEW BRAND FILTER CLICKED",
  save_comment_event: "USER REVIEW SAVE COMMENT CLICKED",
}
export const TeamAnalyticsMap = {
  add_user_event: "USER TEAM ADD USER EVENT",
  send_invite_event: "USER TEAM SEND INVITE EVENT",
  delete_user_event: "USER TEAM DELETE EVENT",
  edit_user_event: "USER TEAM EDIT DETAILS EVENT",
  save_edit_details: "USER TEAM SAVE EDIT DETAILS CLICKED",
}

export const DRAFT_RECIPE_CLICKED = "DRAFT RECIPE CLICKED"
export const DOWNLOAD_DRAFT_RECIPE_BUTTON_CLICKED =
  "DOWNLOAD DRAFT RECIPE BUTTON CLICKED"
export const DOWNLOAD_DRAFT_RECIPE_SUCCESS = "DOWNLOAD DRAFT RECIPE SUCCESS"

export const CHECKOUT_PAGE_VISIT = "CHECKOUT PAGE VISITED"
export const PRICING_PAGE_VISIT = "PRICING PAGE VISITED"
export const PAYMENT_SUCCESS = "PAYMENT SUCCESS"
export const PAYMENT_FAILED = "PAYMENT FAILED"
