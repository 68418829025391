function filterCategories(foodCategories, enabled, labelType) {
  let lebelEnabled
  if (labelType === "LABEL") {
    lebelEnabled = [
      "2",
      "2.1",
      "2.1.1",
      "2.1.2",
      "2.1.3",
      "4",
      "4.1",
      "4.1.1",
      "4.1.1.1",
      "4.1.1.2",
      "4.1.1.3",
      "4.1.2",
      "4.1.2.2",
      "4.1.2.5",
      "4.1.2.6",
      "6",
      "6.1",
      "6.2",
      "6.2.1",
      "6.3",
      "6.4",
      "6.4.1",
      "6.4.2",
      "6.4.3",
      "7",
      "7.1",
      "7.1.1",
      "7.1.1.1",
      "7.1.1.2",
      "7.1.2",
      "7.1.3",
      "7.1.4",
      "7.1.5",
      "7.1.6",
      "7.2",
      "7.2.1",
      "7.2.2",
      "7.2.3",
      "11",
      "11.1",
      "11.1.1",
      "11.1.6",
      "11.5",
      "12.2",
      "12.2.1",
      "14",
      "14.1",
      "14.1.2",
      "14.1.2.1",
      "14.1.2.2",
      "14.1.2.3",
      "14.1.2.4",
      "14.1.3",
      "14.1.3.1",
      "14.1.3.2",
      "14.1.3.3",
      "14.1.3.4",
      "14.1.4",
      "14.1.4.1",
      "14.1.4.2",
      "14.1.4.3",
      "14.1.5",
      "15",
      "15.1",
      "15.2",
      "15.3",
    ]
  } else {
    lebelEnabled = enabled
  }
  let filtered_categories = {
    level_1: [],
    level_2: [],
    level_3: [],
    level_4: [],
  }

  for (let category of lebelEnabled) {
    const category_split = category.split(".")
    const total_category_levels = category_split.length
    let current_category = ""

    for (let i = 0; i < total_category_levels; i++) {
      const level_key = `level_${i + 1}`

      if (i === 0) {
        current_category += category_split[i]
      } else {
        current_category += "." + category_split[i]
      }
      const added_category =
        filtered_categories[level_key].length > 0 &&
        filtered_categories[level_key]?.find(
          (category) => category.level_value === current_category,
        )
      const target_category = foodCategories[level_key]?.find(
        (category) => category.level_value === current_category,
      )

      if (!added_category) {
        filtered_categories[level_key].push(target_category)
      }
    }
  }
  return filtered_categories
}

const applyFilters = (steps, currentCategory) => {
  for (let step_name of Object.keys(steps)) {
    if (steps[step_name].filters !== {}) {
      for (let field_name of Object.keys(steps[step_name].filters)) {
        let filters = steps[step_name].filters[field_name]

        for (let filter of filters) {
          let categories = filter.categories
          let values = filter.values

          // if a matching filter is found for the current category
          if (categories.includes(currentCategory)) {
            steps[step_name].content[field_name] = {
              ...steps[step_name].content[field_name],
              ...values,
            }
            break
          }
        }
      }
    }
  }

  return steps
}

export const ppfutils = {
  filterCategories,
  applyFilters,
}
