import { FieldType } from "../constants/enum"
import { EMAIL_REGEX } from "../Utils/constants"
import { BASE_ROUTES } from "../constants/routes"

const consumerCare = (schemaData, output) => {
  return {
    name: "",
    description: "",
    fields: [
      {
        title: "Select Consumer care",
        type: FieldType.DROPDOWN,
        multiple: false,
        validator: "",
        layout: 10,
        description: "",
        list:
          schemaData &&
          schemaData.consumer_care_address &&
          schemaData.consumer_care_address.map((v) => {
            const returnValue = {
              key: v.id,
              value: `${v.email}, ${v.phone_number}, ${v.address}`,
            }
            return returnValue
          }),
        items: [],
        key: "consumer_care_dropdown_id",
        required: false,
        visible: true,
        default: false,
        onChange: (event) => {
          const selectedValue = event.target.value
          onSelect(selectedValue)
        },
      },
      {
        title: " ",
        type: FieldType.TEXT_BUTTON,
        validator: "",
        layout: 4,
        description: "",
        list: [],
        items: [],
        key: "Create new",
        visible: true,
        help: "",
        link: `${BASE_ROUTES.PPF}/business-profile`,
      },
      {
        title: "Email",
        type: FieldType.TEXT,
        reference: "",
        validators: "",
        layout: 6,
        description: "",
        list: [],
        items: [],
        key: "email",
        required: true,
        visible: true,
        pattern: EMAIL_REGEX,
      },
      {
        title: "Phone No.",
        type: FieldType.TEXT,
        reference: "",
        validators: "",
        layout: 6,
        description: "",
        list: [],
        items: [],
        key: "phone",
        required: true,
        visible: true,
        maxLength: 15,
        pattern: /^\+?[0-9]+$/m,
      },
      {
        title: "Address",
        type: FieldType.TEXT,
        reference: "",
        validators: "",
        layout: 12,
        description: "",
        list: [],
        items: [],
        key: "address",
        required: true,
        visible: true,
        maxLength: 500,
      },
      {
        title: "",
        type: FieldType.RADIO,
        reference: "",
        validator: "",
        layout: 12,
        description: "",
        items: [
          "Address same as manufactured by",
          "Address same as marketed by",
          // "Type your own address",
        ],
        key: "consumercare_addr_same_as_manufactured",
        visible: output && !output.consumer_care_dropdown_id,
        required: false,
        // customStyle: "flex flex-col align-start space-y-2",
        customStyle: "gap-10",
        disable:
          !output?.["manufacturer_by"] ||
          output?.["manufacturer_by"]?.length === 0,
      },
      // {
      //   title: "",
      //   type: FieldType.CHECKBOX,
      //   reference: "",
      //   validator: "",
      //   layout: 12,
      //   description: "",
      //   items: ["Same as manufactured by"],
      //   key: "consumercare_addr_same_as_manufactured",
      //   visible: true,
      //   required: false,
      //   customStyle: "flex align-center [&>div]:py-0 [&>span]:text-sm",
      //   disable:
      //     !output?.["manufacturer_by"] ||
      //     output?.["manufacturer_by"]?.length === 0,
      // },
    ],
  }
}

export default consumerCare
