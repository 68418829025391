export const BusinessType = {
  Manufacturer: "Manufacturer",
  RESTAURANT: "Restaurant",
}

export const FileUploadVariant = {
  SMALL: "small",
}

export const FieldType = {
  TEXT: "input",
  LONG_TEXT: "textbox",
  RADIO: "radio",
  DROPDOWN: "select",
  DROPUP: "selectup",
  DROPDOWNSEARCH: "searchselect",
  CHECKBOX: "checkbox",
  AUTOSUGGEST: "autosuggest",
  SEARCH: "search",
  NUMBER: "number",
  INFO: "info",
  INPUT_TABLE: "inputtable",
  MODAL: "modal",
  COMPONENT: "component",
  MULTI_INPUT: "multiinput",
  FILE: "file",
  MULTI_LINE: "multiline",
  BUTTON: "button",
  TEXT_BUTTON: "textbutton",
  BLANK: "blank",
  CUSTOM: "custom",
  DISPLAY_TEXT: "displaytext",
  MESSAGE: "message",
  DATE: "date",
  DATETIME: "datetime",
  AI_FILE: "ai_file",
  CATEGORY: "category",
  DROPDOWNFLAG: "dropdownflag",
  ADDITIVE_AUTOSUGGEST: "additive_autosuggest",
  USA_ADDITVE_AUTOSUGGEST: "usa_additive_autosuggest",
  RECIPE_DROPDOWN_SEARCH: "recipe_dropdown_search",
}

export const FileTypes = {
  ImageFileTypes: ["image/jpeg", "image/png", "image/gif", "image/svg+xml"],
  ExcelFileTypes: [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
  ],
  ImagePdfTypes: ["application/pdf, image/"],
}

export const FieldTitle = {
  SELECT_COUNTRY: "Select Country",
  SELECT_BRAND: "Select Brand",
  ADD_BRAND: "Add Brand",
  FOOD_NAME: "Food Name",
  TYPE_FLAVOUR_CLASS: "Type / Flavour / Class",
  OTHER_INFORMATION: "Other Information",
  PROPRIETARY_PRODUCT: "Is this a proprietary product?",
  PROPRIETARY_FOOD_NAME: "Proprietary Food Name",
  SHOW_FSSAI_CATEGORY: "Show FSSAI Category on Label",
  STANDARD_FOOD_NAME: "Standard Food Name",
  SINGLE_INGREDIENT: "Is this a single ingredient?",
  INGREDIENTS: "Ingredients",
  ADDITIVES: "Additives",
  NO_ADDITIVES: "No additives are allowed in this food category",
  ORDER_INGREDIENTS: "Drag to Order Ingredients in Descending Order",
  VEGETARIAN_NON_VEGETARIAN: "Vegetarian / Non Vegetarian",
  UPLOAD_VEGAN_CERTIFICATE:
    "Upload Vegan Certificate (Only PDF upto 2MB are allowed)",
  IS_ORGANIC: "Is your product Organic ?",
  UPLOAD_ORGANIC_CERTIFICATE:
    "Upload Jaivik Bharat Certificate (Only PDF upto 2MB are allowed)",
  ORGANIC_CERT_FROM: "Organic Certification from -",
  AGMARK_CERTIFIED: "Is your product AGMARK certified ?",
  UPLOAD_AGMARK_CERTIFICATE:
    "Upload AGMARK certificate (only PDFs upto 2MB are allowed)",
  TEA_BOARD_CERTIFIED: "Is your product Tea Board certified ?",
  UPLOAD_TEA_BOARD_CERTIFICATE:
    "Upload Tea Board certificate (only PDFs upto 2MB are allowed)",
  INDIA_TEA_CERTIFIED: "Is your product India Tea certified ?",
  UPLOAD_INDIA_TEA_CERTIFICATE:
    "Upload India Tea certificate (only PDFs upto 2MB are allowed)",
  INDIA_TEA_LICENSE_NUMBER: "India Tea License Number",
  BIS_CERTIFIED: "Is your product BIS certified ?",
  UPLOAD_BIS_CERTIFICATE:
    "Upload BIS certificate (only PDFs upto 2MB are allowed)",
  BIS_LICENSE_NUMBER: "BIS License Number",
  FORTIFIED: "Is your product Fortified ?",
  UPLOAD_FORTIFICATION_CERTIFICATE:
    "Upload Fortification Certificate (Only PDF upto 2MB are allowed)",
  FORTIFIED_WITH: "Fortified with",
  IRRADIATED: "Is your product irradiated ?",
  PRODUCT_NAME: "Name of the Product",
  RADIATION_PROCESSING_PURPOSE: "Purpose of Radiation Processing",
  OPERATING_LICENSE_NO: "Operating License No.",
  NATURAL_PRODUCT: "Is your product Natural ?",
  PLANT_BASED_PRODUCT: "Is your product Plant based ?",
  CONTAINS_ALLERGENS: "Contains Allergens?",
  CONTAINS: "Contains",
  MAY_CONTAIN: "May Contain",
  MY_PRODUCT_IS: "My product is :",
  NI_VALUES_FROM: "Choose NI values from",
  UPLOAD_LAB_REPORT:
    "Upload Lab Report of Nutrition Analysis (Only PDF up to 2MB are allowed)",
  UPLOAD_YOUR_OWN_VALUES: "Upload NI Values (Only PDF up to 2MB are allowed)",
  SEARCH_RECIPES: "Search from My Recipes",
  TARGET_AUDIENCE: "Select Target Audience",
  AGE_GROUP: "Age Group",
  SERVING_SIZE: "Serving Size",
  SERVING_SIZE_UNIT: "Serving Size Unit",
  NET_QUANTITY: "Net Quantity",
  NET_QUANTITY_UNIT: "Net Quantity Unit",
  MANDATORY_NUTRIENT_INFO: "Mandatory Nutrient Information (per 100 g/ml)",
  OTHER_NUTRIENT_INFO: "Other Nutrient Information (per 100 g/ml)",
  INDUSTRIAL_TRANS_FAT_FREE:
    "Does Your Product Use Industrial Transfat Free Fat/Oil And Contains Less Than 0.2g Per 100g Or 100ml?",
  MANUFACTURED_BY: "Manufactured by",
  PACKED_BY: "Packed by",
  MARKETED_BY: "Marketed by",
  BRAND_OWNER: "Brand Owner",
  IS_IMPORTED: "Is this product imported ?",
  COUNTRY_OF_ORIGIN: "Country of Origin",
  IMPORTED_BY: "Imported by",
  CREATE_NEW_ADDRESS: "Create new address",
  CONSUMER_CARE_DETAILS: "Consumer Care Details",
  USE_AND_STORAGE_INSTRUCTIONS: "Use and Storage Instructions",
  MADE_IN_INDIA: "Is this product Made in India?",
  PLANT_STANOL_ESTERS: "Plant Stanol Esters",
  KCAL_PER_100G: "kcal per 100g or 100ml",
  ISOMALTULOSE: "Isomaltulose",
  ANNATTO_COLOUR: "Annatto colour",
  POLYOLS: "10% or more polyols",
  POLYDEXTROSE: "10% or more polydextrose",
  ADDED_CAFFEINE: "Added caffeine",
  SORBITOL_SYRUP: "10% Or More Sorbitol And Sorbitol Syrup",
  TREHALOSE: "Trehalose",
  MSG: "Monosodium Glutamate",
  DIETARY_FIBRE: "Added Dietary Fibre (Dextrin-soluble fibre)",
  MIXTURE_SWEETENERS: "Mixture of Sweeteners",
  MANDATORY_DECLARATIONS_CATEGORY: "Mandatory Declarations by category",
  REASON_FOR_USING_COMMON_SALT: "Reason for using Common Salt",
  SELECT_WAX_TYPE: "Select Wax Type coated on Fresh Fruit",
  NAME_OF_OIL: "Name of Oil",
  NAME_OF_THE_REFINED_OIL: "Name of the Refined Oil",
  BLENDED_OIL: "Blended Oil",
  PERCENT_OF_COFFEE_IN_MIXTURE: "% of coffee in the mixture",
  PERCENT_OF_CHICORY_IN_MIXTURE: "% of chicory in the mixture",
  COMMON_SALT_FOR: "Common Salt For",
  TYPE_OF_PACKAGING_MATERIAL: "Type of Packaging Material",
  ENTER_NAME_REGISTRATION_NUMBER:
    "Enter Name & Registration Number of Brand Owner",
  SELECT_RECYCLING_ICON: "Select the Appropriate Recycling Icon If Applicable",
  INDICATE_PACK_SHAPE: "Indicate Pack Shape",
  SELECT_UNIT: "Please Select Unit",
  MENTION_PACK_DIMENSIONS: "Mention Pack Dimensions",
  MENTION_LABEL_DIMENSIONS: "Mention Label Dimensions",
  SKU_DETAILS: "SKU Details",
  MRP: "MRP",
  USP: "USP",
  DATE_OF_PACKAGING: "Date of Packaging/Manufacture",
  EXPIRY_DATE: "Use By/Expiry Date",
  BATCH_NUMBER: "Batch Number",
  ADD_BARCODE_IMAGE: "Add Barcode Image",
  UPLOAD_BAR_CODE: "Upload Bar Code (Only Images are allowed)",
  ADD_BARCODE_NUMBER: "Add Barcode Number",
  FSSAI_GUIDELINES: "LM & FSSAI Guidelines",
  ENERGY: "Energy (kcal) per 100g/ml",
  CARBOHYDRATES: "Total Carbohydrate (g) per 100g/ml",
  SUGARS: "Total Sugars (g) per 100g/ml",
  ADDED_SUGARS: "Added Sugars (g) per 100g/ml",
  PROTEIN: "Protein (g) per 100g/ml",
  TOTAL_FAT: "Total Fat (g) per 100g/ml",
  SATURATED_FAT: "Saturated Fat (g) per 100g/ml",
  SODIUM: "Sodium (mg) per 100g/ml",
  CHOLESTEROL: "Cholesterol (mg) per 100g/ml",
  TRANS_FAT: "Trans Fat (g) per 100g/ml",
  DIETARY: "Dietary Fibre (g) per 100g/ml",
  VITAMIN_D_MCG: "Vitamin D (mcg) per 100g/ml ",
  CALCIUM: "Calcium (mg) per 100g/ml ",
  IRON: "Iron (mg) per 100g/ml ",
  POTASSIUM: "Potassium (mg) per 100g/ml ",
  SALT: "Salt (g) per 100g/ml",
  ADDITIVES_CLASS_NAME: "Class Name",
  ADDITIVES_INS_E: "INS/E",
  UK_ADDITIVES_E: "Additive Name/ E Number",
  UK_ADDITIVES_PROPORTION: "Proportion (%)",
  AUS_NZ_ADDITIVES_INS: "Additive Name/ INS Number",
  AUS_NZ_ADDITIVES_PROPORTION: "Proportion (%)",
  ADDITIVES_ADDITIVE_NAME: "Additive Name",
  ADDITIVES_NATURE_OF_COMPOUND: "Nature of compound",
  ADDITIVES_COMMON_NAME: "Common Name",
  ADDITIVES_NAME_OF_THE_ADDITIVE: "Name of the Additive",
  ASPERTAME_METHYL: "Aspartame (Methyl Ester)",
  ACESULFAME_POTASSIUM: "Acesulfame Potassium",
  ASPARTAME_SALT: "Aspartame-Acesulfame Salt",
  SACCHARINS: "SACCHARINS",
  POLYOLS_SWEETNER: "Polyols",
  SORBITOL: "Sorbitol",
  SUCRALOSE: "Sucralose",
  NEOTAME: "Neotame",
  STEVIA_GLYCOSIDE: "Stevia Glycoside.",
  USP_COST_PER_UNIT: "USP (Cost per Unit-Rs.)",
  USP_UNIT: "USP (Unit)",
  USP_AUTO_CALCULATION: "USP Auto Calculation",
  PURITY_WEIGHT_OF_MARKER_COMPOUND: "Purity and Weight % of Marker Compound",
}

export const InfoText = {
  USA: {},
  UK: {},
  IND: {},
  "AUS-NZ": {},
}

export const NoteText = {
  USA: {
    [FieldTitle.FOOD_NAME]: "Ensure only English terms are used",
  },
  UK: {},
  IND: {},
  "AUS-NZ": {},
}

export const HiddenFieldForCountry = {
  USA: [
    FieldTitle.SALT,
    FieldTitle.PROPRIETARY_FOOD_NAME,
    FieldTitle.STANDARD_FOOD_NAME,
    FieldTitle.UPLOAD_ORGANIC_CERTIFICATE,
    FieldTitle.UPLOAD_AGMARK_CERTIFICATE,
    FieldTitle.UPLOAD_TEA_BOARD_CERTIFICATE,
    FieldTitle.UPLOAD_INDIA_TEA_CERTIFICATE,
    FieldTitle.UPLOAD_BIS_CERTIFICATE,
    FieldTitle.UPLOAD_FORTIFICATION_CERTIFICATE,
    FieldTitle.PRODUCT_NAME,
    FieldTitle.RADIATION_PROCESSING_PURPOSE,
    FieldTitle.OPERATING_LICENSE_NO,
    FieldTitle.NATURAL_PRODUCT,
    FieldTitle.PLANT_BASED_PRODUCT,
    FieldTitle.KCAL_PER_100G,
    FieldTitle.PURITY_WEIGHT_OF_MARKER_COMPOUND,
    FieldTitle.ENTER_NAME_REGISTRATION_NUMBER,
    FieldTitle.SELECT_RECYCLING_ICON,
  ],
  UK: [
    FieldTitle.DIETARY,
    FieldTitle.PROPRIETARY_FOOD_NAME,
    FieldTitle.STANDARD_FOOD_NAME,
    FieldTitle.UPLOAD_ORGANIC_CERTIFICATE,
    FieldTitle.UPLOAD_AGMARK_CERTIFICATE,
    FieldTitle.UPLOAD_TEA_BOARD_CERTIFICATE,
    FieldTitle.UPLOAD_INDIA_TEA_CERTIFICATE,
    FieldTitle.UPLOAD_BIS_CERTIFICATE,
    FieldTitle.UPLOAD_FORTIFICATION_CERTIFICATE,
    FieldTitle.PRODUCT_NAME,
    FieldTitle.RADIATION_PROCESSING_PURPOSE,
    FieldTitle.OPERATING_LICENSE_NO,
    FieldTitle.NATURAL_PRODUCT,
    FieldTitle.PLANT_BASED_PRODUCT,
    FieldTitle.KCAL_PER_100G,
    FieldTitle.PURITY_WEIGHT_OF_MARKER_COMPOUND,
    FieldTitle.ENTER_NAME_REGISTRATION_NUMBER,
    FieldTitle.SELECT_RECYCLING_ICON,
  ],
  "AUS-NZ": [
    FieldTitle.DIETARY,
    FieldTitle.SALT,
    FieldTitle.PROPRIETARY_FOOD_NAME,
    FieldTitle.STANDARD_FOOD_NAME,
    FieldTitle.UPLOAD_ORGANIC_CERTIFICATE,
    FieldTitle.UPLOAD_AGMARK_CERTIFICATE,
    FieldTitle.UPLOAD_TEA_BOARD_CERTIFICATE,
    FieldTitle.UPLOAD_INDIA_TEA_CERTIFICATE,
    FieldTitle.UPLOAD_BIS_CERTIFICATE,
    FieldTitle.UPLOAD_FORTIFICATION_CERTIFICATE,
    FieldTitle.PRODUCT_NAME,
    FieldTitle.RADIATION_PROCESSING_PURPOSE,
    FieldTitle.OPERATING_LICENSE_NO,
    FieldTitle.NATURAL_PRODUCT,
    FieldTitle.PLANT_BASED_PRODUCT,
    FieldTitle.KCAL_PER_100G,
    FieldTitle.PURITY_WEIGHT_OF_MARKER_COMPOUND,
    FieldTitle.ENTER_NAME_REGISTRATION_NUMBER,
    FieldTitle.SELECT_RECYCLING_ICON,
  ],
  IND: [FieldTitle.DIETARY, FieldTitle.SALT],
}

export const ListField = {
  [FieldTitle.CONTAINS]: {
    USA: [
      { key: "Milk", value: "Milk" },
      { key: "Egg", value: "Egg" },
      { key: "Fish", value: "Fish" },
      { key: "Crustacean shellfish", value: "Crustacean shellfish" },
      { key: "Tree nuts", value: "Tree nuts" },
      { key: "Peanuts", value: "Peanuts" },
      { key: "Wheat", value: "Wheat" },
      { key: "Soy", value: "Soy" },
      { key: "Sesame", value: "Sesame" },
    ],
    UK: [
      { key: "Celery", value: "Celery" },
      { key: "Cereals Containing Gluten", value: "Cereals Containing Gluten" },
      { key: "Egg", value: "Egg" },
      { key: "Milk", value: "Milk" },
      { key: "Fish", value: "Fish" },
      { key: "Crustacean", value: "Crustacean" },
      { key: "Molluscs", value: "Molluscs" },
      { key: "Tree nuts", value: "Tree nuts" },
      { key: "Peanuts", value: "Peanuts" },
      { key: "Mustard", value: "Mustard" },
      { key: "Soy", value: "Soy" },
      { key: "Sesame", value: "Sesame" },
      { key: "Sulphites", value: "Sulphites" },
      { key: "Lupin", value: "Lupin" },
    ],
    "AUS-NZ": [
      { key: "Wheat", value: "Wheat" },
      { key: "Barley", value: "Barley" },
      { key: "Oats", value: "Oats" },
      { key: "Rye", value: "Rye" },
      { key: "Fish", value: "Fish" },
      { key: "Crustacean", value: "Crustacean" },
      { key: "Mollusc", value: "Mollusc" },
      { key: "Egg", value: "Egg" },
      { key: "Milk", value: "Milk" },
      { key: "Lupin", value: "Lupin" },
      { key: "Peanut", value: "Peanut" },
      { key: "Soy", value: "Soy" },
      { key: "Sesame", value: "Sesame" },
      { key: "Almond", value: "Almond" },
      { key: "Brazil nut", value: "Brazil nut" },
      { key: "Cashew", value: "Cashew" },
      { key: "Hazelnut", value: "Hazelnut" },
      { key: "Macadamia", value: "Macadamia" },
      { key: "Pecan", value: "Pecan" },
      { key: "Pistachio", value: "Pistachio" },
      { key: "Pine nut", value: "Pine nut" },
      { key: "Walnut", value: "Walnut" },
      { key: "Sulphites", value: "Sulphites" },
    ],
    IND: [
      { key: "Wheat", value: "Wheat" },
      { key: "Barley", value: "Barley" },
      { key: "Rye", value: "Rye" },
      { key: "Oat", value: "Oat" },
      { key: "Spelt", value: "Spelt" },
      { key: "Gluten", value: "Gluten" },
      { key: "Milk", value: "Milk" },
      { key: "Egg", value: "Egg" },
      { key: "Nuts", value: "Nuts" },
      { key: "Soy", value: "Soy" },
      { key: "Fish", value: "Fish" },
      { key: "Crustacean", value: "Crustacean" },
      { key: "Sulphite", value: "Sulphite (only if ≥ 10mg/kg)" },
    ],
  },
  [FieldTitle.MAY_CONTAIN]: {
    USA: [
      { key: "Milk", value: "Milk" },
      { key: "Egg", value: "Egg" },
      { key: "Fish", value: "Fish" },
      { key: "Crustacean shellfish", value: "Crustacean shellfish" },
      { key: "Tree nuts", value: "Tree nuts" },
      { key: "Peanuts", value: "Peanuts" },
      { key: "Wheat", value: "Wheat" },
      { key: "Soy", value: "Soy" },
      { key: "Sesame", value: "Sesame" },
    ],
    UK: [
      { key: "Celery", value: "Celery" },
      { key: "Cereals Containing Gluten", value: "Cereals Containing Gluten" },
      { key: "Egg", value: "Egg" },
      { key: "Milk", value: "Milk" },
      { key: "Fish", value: "Fish" },
      { key: "Crustacean", value: "Crustacean" },
      { key: "Molluscs", value: "Molluscs" },
      { key: "Tree nuts", value: "Tree nuts" },
      { key: "Peanuts", value: "Peanuts" },
      { key: "Mustard", value: "Mustard" },
      { key: "Soy", value: "Soy" },
      { key: "Sesame", value: "Sesame" },
      { key: "Sulphites", value: "Sulphites" },
      { key: "Lupin", value: "Lupin" },
    ],
    "AUS-NZ": [
      { key: "Wheat", value: "Wheat" },
      { key: "Fish", value: "Fish" },
      { key: "Crustacean", value: "Crustacean" },
      { key: "Mollusc", value: "Mollusc" },
      { key: "Egg", value: "Egg" },
      { key: "Milk", value: "Milk" },
      { key: "Lupin", value: "Lupin" },
      { key: "Peanut", value: "Peanut" },
      { key: "Soy", value: "Soy" },
      { key: "Sesame", value: "Sesame" },
      { key: "Almond", value: "Almond" },
      { key: "Brazil nut", value: "Brazil nut" },
      { key: "Cashew", value: "Cashew" },
      { key: "Hazelnut", value: "Hazelnut" },
      { key: "Macadamia", value: "Macadamia" },
      { key: "Pecan", value: "Pecan" },
      { key: "Pistachio", value: "Pistachio" },
      { key: "Pine nut", value: "Pine nut" },
      { key: "Walnut", value: "Walnut" },
      { key: "Barley", value: "Barley" },
      { key: "Oats", value: "Oats" },
      { key: "Rye", value: "Rye" },
      { key: "Sulphites", value: "Sulphites" },
    ],
    IND: [
      { key: "Wheat", value: "Wheat" },
      { key: "Barley", value: "Barley" },
      { key: "Rye", value: "Rye" },
      { key: "Oat", value: "Oat" },
      { key: "Spelt", value: "Spelt" },
      { key: "Gluten", value: "Gluten" },
      { key: "Milk", value: "Milk" },
      { key: "Egg", value: "Egg" },
      { key: "Nuts", value: "Nuts" },
      { key: "Soy", value: "Soy" },
      { key: "Fish", value: "Fish" },
      { key: "Crustacean", value: "Crustacean" },
      { key: "Sulphite", value: "Sulphite (only if ≥ 10mg/kg)" },
    ],
  },
}

export const DisabledFieldForCountry = {
  USA: [
    FieldTitle.PROPRIETARY_PRODUCT,
    FieldTitle.SHOW_FSSAI_CATEGORY,
    FieldTitle.IS_ORGANIC,
    FieldTitle.AGMARK_CERTIFIED,
    FieldTitle.TEA_BOARD_CERTIFIED,
    FieldTitle.INDIA_TEA_CERTIFIED,
    FieldTitle.INDIA_TEA_LICENSE_NUMBER,
    FieldTitle.BIS_CERTIFIED,
    FieldTitle.FORTIFIED,
    FieldTitle.IRRADIATED,
    FieldTitle.NATURAL_PRODUCT,
    FieldTitle.PLANT_BASED_PRODUCT,
    FieldTitle.MY_PRODUCT_IS,
    FieldTitle.INDUSTRIAL_TRANS_FAT_FREE,
    FieldTitle.TARGET_AUDIENCE,
    FieldTitle.AGE_GROUP,
    FieldTitle.TYPE_OF_PACKAGING_MATERIAL,
    FieldTitle.ADDITIVES_COMMON_NAME,
    FieldTitle.ADDITIVES_INS_E,
    FieldTitle.ADDITIVES_NAME_OF_THE_ADDITIVE,
    FieldTitle.ADDITIVES_NATURE_OF_COMPOUND,
    FieldTitle.PLANT_STANOL_ESTERS,
    FieldTitle.ISOMALTULOSE,
    FieldTitle.ANNATTO_COLOUR,
    FieldTitle.POLYOLS,
    FieldTitle.POLYDEXTROSE,
    FieldTitle.ADDED_CAFFEINE,
    FieldTitle.SORBITOL_SYRUP,
    FieldTitle.TREHALOSE,
    FieldTitle.MSG,
    FieldTitle.DIETARY_FIBRE,
    FieldTitle.MIXTURE_SWEETENERS,
    FieldTitle.ASPERTAME_METHYL,
    FieldTitle.ACESULFAME_POTASSIUM,
    FieldTitle.ASPARTAME_SALT,
    FieldTitle.SACCHARINS,
    FieldTitle.POLYOLS_SWEETNER,
    FieldTitle.SORBITOL,
    FieldTitle.SUCRALOSE,
    FieldTitle.NEOTAME,
    FieldTitle.STEVIA_GLYCOSIDE,
    FieldTitle.USP,
    FieldTitle.USP_AUTO_CALCULATION,
    FieldTitle.USP_COST_PER_UNIT,
    FieldTitle.USP_UNIT,
    FieldTitle.MADE_IN_INDIA,
    FieldTitle.ADDITIVES_ADDITIVE_NAME,
    FieldTitle.SEARCH_RECIPES,
  ],
  UK: [
    FieldTitle.PROPRIETARY_PRODUCT,
    FieldTitle.SHOW_FSSAI_CATEGORY,
    FieldTitle.IS_ORGANIC,
    FieldTitle.AGMARK_CERTIFIED,
    FieldTitle.TEA_BOARD_CERTIFIED,
    FieldTitle.INDIA_TEA_CERTIFIED,
    FieldTitle.INDIA_TEA_LICENSE_NUMBER,
    FieldTitle.BIS_CERTIFIED,
    FieldTitle.FORTIFIED,
    FieldTitle.IRRADIATED,
    FieldTitle.NATURAL_PRODUCT,
    FieldTitle.PLANT_BASED_PRODUCT,
    FieldTitle.MY_PRODUCT_IS,
    FieldTitle.INDUSTRIAL_TRANS_FAT_FREE,
    FieldTitle.TARGET_AUDIENCE,
    FieldTitle.AGE_GROUP,
    FieldTitle.TYPE_OF_PACKAGING_MATERIAL,
    FieldTitle.SEARCH_RECIPES,
    FieldTitle.MADE_IN_INDIA,
    FieldTitle.PLANT_STANOL_ESTERS,
    FieldTitle.ISOMALTULOSE,
    FieldTitle.ANNATTO_COLOUR,
    FieldTitle.POLYOLS,
    FieldTitle.POLYDEXTROSE,
    FieldTitle.ADDED_CAFFEINE,
    FieldTitle.SORBITOL_SYRUP,
    FieldTitle.TREHALOSE,
    FieldTitle.MSG,
    FieldTitle.DIETARY_FIBRE,
    FieldTitle.MIXTURE_SWEETENERS,
    FieldTitle.ASPERTAME_METHYL,
    FieldTitle.ACESULFAME_POTASSIUM,
    FieldTitle.ASPARTAME_SALT,
    FieldTitle.SACCHARINS,
    FieldTitle.POLYOLS_SWEETNER,
    FieldTitle.SORBITOL,
    FieldTitle.SUCRALOSE,
    FieldTitle.NEOTAME,
    FieldTitle.STEVIA_GLYCOSIDE,
    FieldTitle.USP,
    FieldTitle.USP_AUTO_CALCULATION,
    FieldTitle.USP_COST_PER_UNIT,
    FieldTitle.USP_UNIT,
  ],
  "AUS-NZ": [
    FieldTitle.PROPRIETARY_PRODUCT,
    FieldTitle.SHOW_FSSAI_CATEGORY,
    FieldTitle.IS_ORGANIC,
    FieldTitle.AGMARK_CERTIFIED,
    FieldTitle.TEA_BOARD_CERTIFIED,
    FieldTitle.INDIA_TEA_CERTIFIED,
    FieldTitle.INDIA_TEA_LICENSE_NUMBER,
    FieldTitle.BIS_CERTIFIED,
    FieldTitle.FORTIFIED,
    FieldTitle.IRRADIATED,
    FieldTitle.NATURAL_PRODUCT,
    FieldTitle.PLANT_BASED_PRODUCT,
    FieldTitle.MY_PRODUCT_IS,
    FieldTitle.INDUSTRIAL_TRANS_FAT_FREE,
    FieldTitle.TARGET_AUDIENCE,
    FieldTitle.AGE_GROUP,
    FieldTitle.TYPE_OF_PACKAGING_MATERIAL,
    FieldTitle.ADDITIVES_CLASS_NAME,
    FieldTitle.ADDITIVES_COMMON_NAME,
    FieldTitle.ADDITIVES_INS_E,
    FieldTitle.ADDITIVES_ADDITIVE_NAME,
    FieldTitle.ADDITIVES_NAME_OF_THE_ADDITIVE,
    FieldTitle.ADDITIVES_NATURE_OF_COMPOUND,
    FieldTitle.PLANT_STANOL_ESTERS,
    FieldTitle.ISOMALTULOSE,
    FieldTitle.ANNATTO_COLOUR,
    FieldTitle.POLYOLS,
    FieldTitle.POLYDEXTROSE,
    FieldTitle.ADDED_CAFFEINE,
    FieldTitle.SORBITOL_SYRUP,
    FieldTitle.TREHALOSE,
    FieldTitle.MSG,
    FieldTitle.DIETARY_FIBRE,
    FieldTitle.MIXTURE_SWEETENERS,
    FieldTitle.ASPERTAME_METHYL,
    FieldTitle.ACESULFAME_POTASSIUM,
    FieldTitle.ASPARTAME_SALT,
    FieldTitle.SACCHARINS,
    FieldTitle.POLYOLS_SWEETNER,
    FieldTitle.SORBITOL,
    FieldTitle.SUCRALOSE,
    FieldTitle.NEOTAME,
    FieldTitle.STEVIA_GLYCOSIDE,
    FieldTitle.SEARCH_RECIPES,
    // FieldTitle.USP,
    // FieldTitle.USP_AUTO_CALCULATION,
    // FieldTitle.USP_COST_PER_UNIT,
    // FieldTitle.USP_UNIT,
    FieldTitle.MADE_IN_INDIA,
  ],
  IND: [],
}

export const NonRequiredFieldForCountry = {
  USA: [
    FieldTitle.VEGETARIAN_NON_VEGETARIAN,
    FieldTitle.TARGET_AUDIENCE,
    FieldTitle.AGE_GROUP,
    FieldTitle.ADDED_SUGARS,
    FieldTitle.AGMARK_CERTIFIED,
    FieldTitle.TEA_BOARD_CERTIFIED,
    FieldTitle.INDIA_TEA_CERTIFIED,
    FieldTitle.INDIA_TEA_LICENSE_NUMBER,
    FieldTitle.BIS_CERTIFIED,
    FieldTitle.FORTIFIED,
    FieldTitle.IRRADIATED,
    FieldTitle.NATURAL_PRODUCT,
    FieldTitle.PLANT_BASED_PRODUCT,
    FieldTitle.MY_PRODUCT_IS,
    FieldTitle.INDUSTRIAL_TRANS_FAT_FREE,
    FieldTitle.TYPE_OF_PACKAGING_MATERIAL,
  ],
  UK: [
    FieldTitle.VEGETARIAN_NON_VEGETARIAN,
    FieldTitle.TARGET_AUDIENCE,
    FieldTitle.AGE_GROUP,
    FieldTitle.ENERGY,
    FieldTitle.CARBOHYDRATES,
    FieldTitle.SUGARS,
    FieldTitle.PROTEIN,
    FieldTitle.TOTAL_FAT,
    FieldTitle.SATURATED_FAT,
    FieldTitle.TYPE_OF_PACKAGING_MATERIAL,
  ],
  "AUS-NZ": [
    FieldTitle.VEGETARIAN_NON_VEGETARIAN,
    FieldTitle.ENERGY,
    FieldTitle.CARBOHYDRATES,
    FieldTitle.SUGARS,
    FieldTitle.PROTEIN,
    FieldTitle.TOTAL_FAT,
    FieldTitle.SATURATED_FAT,
    FieldTitle.SODIUM,
    FieldTitle.TYPE_OF_PACKAGING_MATERIAL,
  ],
  IND: [],
}

export const Country = {
  USA: "United States of America",
  UK: "United Kingdom",
  "AUS-NZ": "Australia & New Zealand",
  IND: "India",
}
